




































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {VueTelInput} from "vue-tel-input";


@Component({
    components: {VueTelInput}
})
export default class InternationalPhoneNumberInput extends Vue {

    @Prop({})
    id: string;

    @Prop({})
    name: string;

    @Prop()
    label: string;

    @Prop()
    value: string;

    @Prop({default: false})
    required: boolean;

    @Prop({default: false})
    justShow: boolean;

    @Prop({default: false})
    disabled: boolean;

    @Prop({default: 'left'})
    align: string;

    @Prop()
    suffix: string;

    @Prop({default:true})
    editable:boolean;

    asterisk="";
    topLabel = false;

    mounted() {
        if (this.required && this.label !== '') {
            this.asterisk = ' *';
        }
    }

    emitValue(value: Object) {
        this.$emit('input', value)
    }

}
