


















































import {Vue} from "vue-class";
import Component from "vue-class-component";
import JustificatoriesDropdown from "@/components/justificatories/justif-dropdown.vue";
import Radio from "@/components/inputs/radio.vue";
import Foot from "@/components/complex-components/footer.vue";
import justificatoryState from "@/store/modules/justificatoryState";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";

@Component({
    components: {JustificatoriesDropdown, Foot,Radio,DropMenuWrapper},
})
export default class Justificatories extends Vue {

    justificatory = justificatoryState.state;

    idTitle = "Pièce d'identité de l'emprunteur *";
    coBorrowerdTitle = "Pièce d'identité du co-emprunteur";
    ribTitle = "RIB *";
    showBloc1  = true;
    slotsManager = new SlotsManagerImpl();
    right={text:"Confirmer", callback: this.goToNextPage,show:true} ;
    left={show:false} ;
    active = "";

    toggleActivate(title) {
        if (this.active == title) {
            this.active = "";
        } else {
            this.active = title;
        }
    }

    goToNextPage() {
      if(this.slotsManager.shownSlot > 0){
        this.$emit("nextPage");
      }else{
        this.slotsManager.incrementTheCounter();
        this.showBloc1 = false;
      }

    }
    goToMenu(index: number) {
      this.slotsManager.showTheSlot(index);
    }



}

