


















import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import CustomInput from "./custom-input"

@Component({})
export default class Dropdown extends CustomInput {

    @Prop()
    selectOptions: Array<{display:string,value:any}>;

    @Prop({default: 'false'})
    required: string;

    @Prop({default: ""})
    label: string;

    mounted(){
        if (this.required == 'true' && this.label !== '') {
            this.asterisk = ' *';
        }
    }
}

