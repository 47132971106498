import subscriptionState from "@/store/modules/subscriptionState";
import simulationState from "@/store/modules/subscription/simulationState";

const state:{} = {
};

// getters
const getters = {

};

// actions
const actions = {

};

// mutations
const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
