























import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import File from "@/components/inputs/file.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import {Prop} from "vue-property-decorator";
import DefaultInput from "@/components/inputs/default-input.vue";
import NotificationDisplayer from "@/components/displayers/notification-displayer.vue";
import {Action} from "vuex-class";

@Component({
    components: {NotificationDisplayer, DefaultInput, BasicModal, File, DropMenuWrapper}
})
export default class ModalSendMail extends Vue {

    @Action('notifications/addError') addError;

    @Prop({default: false})
    isOpened: boolean;

    onOk() {
        this.$emit('ok')
    }
}

