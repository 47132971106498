
import {Notification, NotificationStatus, NotificationType} from "@/typings/Notifications";
import {generateUniqueId} from "@/utils/IdGenerator";

const state:{notifications:Array<Notification>} = {
    /**
     * The notifications that are displaying or are waiting to be displayed
     */
    notifications: Array<Notification>()
};

// getters
const getters = {

};

// actions
const actions = {
    /**
     * Add an error notification to the notifications list
     * @param state
     * @param commit
     * @param payload {
     *      message the message to be displayed
     *      type the notification type
     *  }
     * @returns {Promise<object>}
     */
    addError: ({state,commit},payload:{message:string, type ?: NotificationType})=>{
        return new Promise( (resolve,reject) => {
            const notification: Notification = {
                id: generateUniqueId(state.notifications),
                message: payload.message,
                status: NotificationStatus.ERROR,
                type: payload.type || NotificationType.TOAST,
                closedCallback: (payload?)=>{
                    resolve(payload);
                }
            };
            commit('addNotification', notification);
        });
    },
    /**
     * Add an info notification to the notifications list
     * @param state
     * @param commit
     * @param payload {
     *      message the message to be displayed
     *      type the notification type
     *  }
     * @returns {Promise<object>}
     */
    addInfo: ({state,commit},payload:{message:string, type ?: NotificationType})=>{
        return new Promise( (resolve,reject) => {
            const notification: Notification = {
                id: generateUniqueId(state.notifications),
                message: payload.message,
                status: NotificationStatus.INFO,
                type: payload.type || NotificationType.TOAST,
                closedCallback: (payload?)=>{
                    resolve(payload);
                }
            };
            commit('addNotification', notification);
        });
    },
    /**
     * Add an info notification to the notifications list
     * @param state
     * @param commit
     * @param payload {
     *      message the message to be displayed
     *      type the notification type
     *  }
     * @returns {Promise<object>}
     */
    addSuccess: ({state,commit},payload:{message:string, type ?: NotificationType})=>{
        return new Promise( (resolve,reject) => {
            const notification: Notification = {
                id: generateUniqueId(state.notifications),
                message: payload.message,
                status: NotificationStatus.SUCCESS,
                type: payload.type || NotificationType.TOAST,
                closedCallback: (payload)=>{
                    resolve(payload);
                }
            };
            commit('addNotification', notification);
        });
    },
    /**
     * return the notification corresponding to the given id
     * @param state
     * @param notificationId
     * @returns {number|object|undefined}
     */
    getById: ({state}, notificationId)=>{
        return state.notifications.find(notification => notification.id==notificationId);
    }
};

// mutations
const mutations = {
    /**
     * add a notification to the notifications list
     * @param state
     * @param notification
     */
    addNotification : (state, notification: Notification) => {
        state.notifications.push(notification);
    },
    /**
     * Call the closedCallback of the given notification then delete it from the list
     * @param state
     * @param notification
     */
    deleteNotification : (state, notification : Notification) => {
        let notificationIndex = state.notifications.findIndex(notificationItem => notification.id==notificationItem.id);
        if(notificationIndex != -1 && state.notifications[notificationIndex].closedCallback != null) {
            state.notifications[notificationIndex].closedCallback();
        }
        state.notifications.splice(notificationIndex,1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
