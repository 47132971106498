import {BaseValidatorRule} from "../BaseValidatorRule";
export class SometimesRequiredRule extends BaseValidatorRule{

    ruleIsActive: boolean

    constructor(errormessage: string = "", ruleIsActive: boolean = true) {
        super(errormessage)
        this.ruleIsActive = ruleIsActive
    }

    changeActivation(newValue: boolean) {
        this.ruleIsActive = newValue
    }

    getDefaultErrorMessage(): string {
        return "Champ obligatoire";
    }

    validate(value: any): boolean {
        if(this.ruleIsActive) {
            let valid = false;
            if(typeof value == "string")
                valid = value!=="";
            else
                valid = value !== null;
            this.hasPassed = valid;
            return valid;
        }
        else {
            this.hasPassed = true
            return true
        }

    }
}