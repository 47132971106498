import index from "@/store";

export interface SlotsManager {
    shownSlot: number,
    isSlotShown(index: number): boolean,
    isModificationButtonShown(index: number): boolean,
    showTheSlot(index: number): void,
    incrementTheCounter(): void
}


export class SlotsManagerImpl implements SlotsManager {
    shownSlot: number;


    constructor()
    {
        this.shownSlot = 0;
    }


    isSlotShown(index: number): boolean
    {
        return index == this.shownSlot;
    }

    isModificationButtonShown(index: number): boolean
    {
        return index < this.shownSlot;
    }

    showTheSlot(index: number)
    {
        this.shownSlot = index;
    }

    incrementTheCounter(): void {
        this.shownSlot++;
    }

}
