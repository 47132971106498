



















































































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {
    ContextAndHeaderManager,
    WalletContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import {Action} from "vuex-class";
import EntryDatatable from "@/components/complex-components/entryDatatable.vue";
import Radio from "@/components/inputs/radio.vue";
import DefaultInput from "@/components/inputs/default-input.vue";
import Dropdown from "@/components/inputs/dropdown.vue";
import OldInput from "@/components/inputs/old-input.vue";
import InformationDisplay from "@/components/displayers/information-display.vue";
import DesignTable from "@/components/complex-components/design-table.vue";
import APRCalculator from "@/utils/APRCalculator";
import PurchaseConfirmWrapper from "@/views/modals/PurchaseConfirmWrapper.vue";
import AppHeader from "@/components/headers/AppHeader.vue";
import PageHeader from "@/components/headers/PageHeader.vue";

@Component({
    components: {
        AppHeader,PageHeader,
        PurchaseConfirmWrapper,
        EntryDatatable, Radio, Dropdown, DefaultInput, OldInput, InformationDisplay, DesignTable
    }
})
export default class PortefeuilleAchat extends Vue implements ContextAndHeaderManager {

    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;

    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

    rightPartFontSize = '12px';
    rightPartInputHeight = '18px';
    rightPartInputPadding = '0 2px';

    checkoutNumber = '';
    dealNumber = '';

    possibleFoundingValues = [{label: 'A crédit', value: 'CREDIT'},{label: 'Au comptant', value: 'COMPTANT'}];
    founding = "CREDIT";

    possibleNatureValues = [
        {display: 'Salon', value: 'LIVING-ROOM'},
        {display: 'Literie', value: 'BEDDING'},
        {display: 'Salle de bain', value: 'BATH-ROOM'},
        {display: 'Cuisine', value: 'KITCHEN'},
        {display: 'Accessoires', value: 'ACCESSORIES'}
    ];
    nature = "KITCHEN";

    possibleScaleValues = [
        {display: '3 mois payant', value: '3-M-P'},
        {display: '5 fois payant', value: '5-P'},
        {display: '10 fois payant', value: '10-P'},
        {display: '20 fois payant', value: '20-P'},
        {display: '3 fois gratuit', value: '3-F'},
        {display: '5 fois gratuit', value: '5-F'},
        {display: '10 fois gratuit', value: '10-F'},
        {display: '20 fois gratuit', value: '20-F'},
        {display: 'Report 2 mois', value: '2-R'},
        {display: 'Autre barème', value: 'OTHERSCALE'}
    ];
    scale = "3-F";

    command: string = '6845650056';
    purchaseAmount: string = '150.00';
    bringAmount: string = '0.00';
    bringPercent: string = '0.00';
    echeanceNumber = '1';

    simulationDone = false;
    modalConfirmPurchaseDisplayed = false;

    fees = 0.0;
    tauxAnnuelDebiteurFixe = 0.0;
    APR = 0.000;

    get numberEditable() {
        return "OTHERSCALE" == this.scale || "2-R" == this.scale;
    }

    created() {
        this.updateHeaderOfPageOnChange();

        this.setEcheanceNumberFromDropdown();
    }

    simulate() {
        this.simulationDone = !isNaN(parseFloat(this.echeanceNumber)) && parseFloat(this.amount_credit()) > 0;
    }

    undoSimulation() {
        this.simulationDone = null;

    }

    confirm() {
        this.modalConfirmPurchaseDisplayed = true;
    }

    confirmPurchase() {
        this.$router.push('/portefeuille/' + this.$route.params.foldernumber + '/details');
    }

    setEcheanceNumberFromDropdown() {
        if ("OTHERSCALE" != this.scale) {
            this.echeanceNumber = (this.scale.split("-"))[0];
        }
    }

    setEcheanceNumberFromInput(event) {
        this.echeanceNumber = event;
    }

    pourcent_amount() {
        let bringAmount = parseFloat(this.bringAmount);
        let purchaseAmount = parseFloat(this.purchaseAmount);
        let bringPourcent = parseFloat((Math.round(((bringAmount * 100) / purchaseAmount) * 100) / 100).toString());

        if (isNaN(bringPourcent) || !isFinite(bringPourcent)) {
            bringPourcent = 0;
        }

        return this.bringPercent = bringPourcent.toFixed(2);
    }

    amount_credit() {
        let bringAmount = parseFloat(this.bringAmount);
        let purchaseAmount = parseFloat(this.purchaseAmount);

        return (purchaseAmount - bringAmount).toFixed(2);
    }

    createDatatable() {

        let duration = parseInt(this.echeanceNumber);
        let totalAmount = parseFloat(this.amount_credit());

        if (!this.isScaleWithFee()) {
            this.APR = 0.00;
            this.tauxAnnuelDebiteurFixe = 0.00;
        } else {
            this.tauxAnnuelDebiteurFixe = 4.793;
            this.APR = (APRCalculator.calculate(this.tauxAnnuelDebiteurFixe, duration, totalAmount, this.fees) * 100);
        }

        if (this.founding === 'CREDIT') {

            let totalAmount = 0;
            let deadlineAmount = 0;
            let nbDeadline = parseInt(this.echeanceNumber);
            let lastDeadlineAmount = 0;

            if (isNaN(nbDeadline) || nbDeadline == 0)
                nbDeadline = 1;

            if (!this.isScaleWithFee()) {
                totalAmount = parseFloat(this.amount_credit());
                deadlineAmount = totalAmount / nbDeadline;
                lastDeadlineAmount = deadlineAmount;
            } else {
                totalAmount = parseFloat(this.amount_credit()) + parseFloat(this.amount_credit()) * (this.APR / 100);
                deadlineAmount = this.getMonthlyPayment();
                lastDeadlineAmount = totalAmount - ((nbDeadline - 1) * this.getMonthlyPayment());
            }

            if (lastDeadlineAmount < 0) {
                deadlineAmount = totalAmount / nbDeadline;
                lastDeadlineAmount = totalAmount / nbDeadline;
            }

            let strDeadlineAmount = deadlineAmount.toFixed(2).replace('.', ',');
            let strLastDeadlineAmount = lastDeadlineAmount.toFixed(2).replace(".", ",");

            let middleNbDeadline = Math.max(nbDeadline - 1, 0).toString();

            let datatable = [];

            if (nbDeadline == 2) {
                datatable = [
                    ['', 'Hors assurance', 'Avec Assurance'],
                    ['1ère échéance de', strDeadlineAmount + ' €', strDeadlineAmount + ' €'],
                    ['Suivi de', strLastDeadlineAmount + ' €', strLastDeadlineAmount + ' €'],
                    ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmount.toFixed(2).replace('.', ',') + ' €'],
                ]
            } else if (nbDeadline == 1) {
                datatable = [
                    ['', 'Hors assurance', 'Avec Assurance'],
                    ['1ère échéance de', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmount.toFixed(2).replace('.', ',') + ' €'],
                    ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmount.toFixed(2).replace('.', ',') + ' €'],
                ]
            } else {
                datatable = [
                    ['', 'Hors assurance', 'Avec Assurance'],
                    ['1ère échéance de', strDeadlineAmount + ' €', strDeadlineAmount + ' €'],
                    ['Suivi de', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmount + ' €', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmount + ' €'],
                    ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmount.toFixed(2).replace('.', ',') + ' €'],
                ]
            }

            return datatable;
        }
        else {
            let totalAmount = parseFloat(this.purchaseAmount) - parseFloat(this.bringAmount);
            return [
                [''],
                ['Montant total dû *', totalAmount.toFixed(2).replace('.',',')],
            ];
        }
    }

    getMonthlyPayment() {
        let duration = parseInt(this.echeanceNumber);
        let totalAmount = parseFloat(this.amount_credit());
        return APRCalculator.monthlyPayment(this.tauxAnnuelDebiteurFixe, duration, totalAmount + this.fees)
    }

    isScaleWithFee() {
        return this.scale === "3-M-P"
            || this.scale === "5-P"
            || this.scale === "10-P"
            || this.scale === "20-P"
            || this.scale === "2-R";
    }
}

applyMixins(PortefeuilleAchat, [WalletContextAndHeaderManagerImpl]);

