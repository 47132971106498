import BaseValidator from "../../validation/BaseValidator";
import Vue from "vue";
import {BaseValidatorRule} from "@/validation/BaseValidatorRule";
import {RequiredRule, SometimesRequiredRule} from "../../validation/rules";

const state:{validators:{[validatorGroup: string]:{[property: string]:BaseValidator}},shouldDisplayErrorMessageArray: string[]} = {
    validators: <{[validatorGroup: string]:{[property: string]:BaseValidator}}>{},
    shouldDisplayErrorMessageArray: []
};


const getters = {

    hasNoErrors: state => (validatorGroup:string) => {
        if(validatorGroup && state.validators[validatorGroup] != null) {
            return validationOfAllData(state.validators[validatorGroup]);
        }
        else{
            let allValid;
            for(let groupOfValidators of state.validators)
               allValid = validationOfAllData(groupOfValidators);

            return allValid
        }
    },

    isValid: state => (validatorGroup:string, property:string) => {
        return state.validators[validatorGroup][property].valid;
    },

    getValidationMessages: state => (validatorGroup:string, property:string) => {
        return state.validators[validatorGroup][property].rules
            .filter(rule => !rule.hasPassed)
            .map(rule => rule.errorMessage);
    },

    validatorHasRule: state => (validatorGroup:string, property:string, type) => {
        for(let rule of state.validators[validatorGroup][property].rules){
            if(rule instanceof type) return true;
        }
        return false;
    },
    
    shouldDisplayErrorMessage: state => (validationGroup : string) => {
        return state.shouldDisplayErrorMessageArray.indexOf(validationGroup)!=-1
    },

    getRuleOfType: state => (parameters: {validatorGroup:string, property:string, type}) => {
        for(let rule of state.validators[parameters.validatorGroup][parameters.property].rules) {
            if(rule instanceof parameters.type)
                return rule;
        }
        return null;
    }

};


// actions
const actions = {
    addValidator({commit,state}, payload:{instance:Vue,validatorGroup: string,property:string, validator: BaseValidator}){

        commit('addValidator',
            {
                validatorGroup:payload.validatorGroup,
                property: payload.property,
                validator: payload.validator
            });
        state.validators[payload.validatorGroup][payload.property].run();

        //noinspection TypeScriptUnresolvedFunction
        payload.instance.$watch(payload.property,(newValue) => {
            commit('setValidatorValue',
                {
                    validatorGroup:payload.validatorGroup,
                    property: payload.property,
                    value: newValue
                });
            state.validators[payload.validatorGroup][payload.property].run();
        })
    },

    switchSometimesRequiredRuleActivation({getters, state}, payload:{validatorGroup: string, validatorProperty: string, newValue: boolean}) {
        let ruleSometimesRequired = getters.getRuleOfType(state)({validatorGroup: payload.validatorGroup,property: payload.validatorProperty, type: SometimesRequiredRule});
        if(ruleSometimesRequired) {
            ruleSometimesRequired.changeActivation(payload.newValue);
            state.validators[payload.validatorGroup][payload.validatorProperty].run();
        }
    },

    addRequiredRuleToValidators({commit,state},payload:{validatorProperty:string, validatorGroup:string, sometimesRulesActivator?:boolean}) {
        commit('addRuleToValidator',{validatorGroup: payload.validatorGroup, property: payload.validatorProperty, newRule: new RequiredRule()});
        state.validators[payload.validatorGroup][payload.validatorProperty].run()
        /*
        for(let data of requiredDataPerGrid[payload.gridLabel][payload.validatorGroup]) {
            if(data.isAlwaysRequired)
                commit('addRuleToValidator',{validatorGroup: payload.validatorGroup, property: data.property, newRule: new RequiredRule()});
            else
                commit('addRuleToValidator',{validatorGroup: payload.validatorGroup, property: data.property, newRule: new SometimesRequiredRule("", payload.sometimesRulesActivator)});
        }
         */
    }
};

// mutations
const mutations = {
    addValidator(state, payload:{validatorGroup: string, property:string, validator: BaseValidator}){
        if(!state.validators[payload.validatorGroup])
            state.validators[payload.validatorGroup] = {};
        state.validators[payload.validatorGroup][payload.property] = payload.validator;
    },
    addRuleToValidator(state, payload:{validatorGroup: string, property:string, newRule: BaseValidatorRule}) {
        state.validators[payload.validatorGroup][payload.property].rules.push(payload.newRule)
    },
    setValidatorValue(state, payload:{validatorGroup: string, property:string, value: any}){
        state.validators[payload.validatorGroup][payload.property].value = payload.value;
        state.validators[payload.validatorGroup][payload.property].touched = true;
    },
    addValidatorGroupToShouldDisplayErrorMessage(state, payload: string) {
        if ((payload in state.validators) && !(payload in state.shouldDisplayErrorMessageArray))
            state.shouldDisplayErrorMessageArray.push(payload);
    },
    removeValidatorGroupFromShouldDisplayErrorMessage(state, payload: string) {
        let index = state.shouldDisplayErrorMessageArray.indexOf(payload);
        if (index>=0)
            state.shouldDisplayErrorMessageArray.splice(index);
    }
};

function validationOfAllData(validators: {[property: string]:BaseValidator}){
    for(let validator in validators){
        if(!validators[validator].valid) {
            return false;
        }
    }

    return true;
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}

