import {BaseValidatorRule} from "../BaseValidatorRule"
    ;
export class PasswordRule extends BaseValidatorRule{

    getDefaultErrorMessage(): string {
        return "Le mot de passe doit contenir 8 caractères, dont au moins une majuscule, une minuscule et chiffre";
    }

    validate(value: any):boolean {
        if(value==null || value==""){
            return true;
        }
        return value.match(/[0-9]+/)!=null && value.match(/[a-z]+/)!=null && value.match(/[A-Z]+/)!=null && value.match(/.{8,}/)!=null;
    }
}