var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lk-input full-width"},[_c('div',{staticClass:"input-edit",class:[
             {'just-show': _vm.justShow || _vm.disabled},
                {'read-only': !_vm.editable},
                {'input-w-suffix': _vm.suffix},
                {'error': _vm.shouldShowErrorMessage},
                {'valid': _vm.dirty && _vm.validatorName && !_vm.shouldShowErrorMessage} ]},[_c('input',{ref:"input",staticClass:"form-input",class:{'float-number-not-empty': _vm.type === 'number' && _vm.value != '' && !_vm.justShow && !_vm.disabled},style:({
                   'text-align': _vm.textAlignment
               }),attrs:{"type":_vm.inputType,"align":_vm.textAlignment,"inputmode":_vm.inputmode,"required":_vm.required,"justShow":_vm.justShow,"disabled":_vm.disabled,"step":_vm.type==='number'?_vm.floatStep:'1',"maxlength":_vm.maxLength,"readonly":!_vm.editable || _vm.justShow},domProps:{"value":_vm.value},on:{"blur":function($event){_vm.dirty = true},"focus":function($event){return _vm.$emit('focused',true)},"input":function($event){return _vm.emitValue($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter', true)}}}),_c('label',{staticClass:"label form-label",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+_vm._s(_vm.asterisk)+" ")]),(_vm.suffix)?_c('div',{staticClass:"input-suffix"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()]),(_vm.shouldShowErrorMessage)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.getValidationMessages(_vm.validatorGroup,_vm.validatorName)[0])+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }