
























































































































































































































































































































































































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {Action, Getter, Mutation, namespace} from "vuex-class";
import {
    ContextAndHeaderManager,
    SubscriptionContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import Simulation from "@/views/souscription/Simulation.vue";
import Informations from "@/views/souscription/Informations.vue";
import Situation from "@/views/souscription/Situation.vue";
import RevenusEtBanque from "@/views/souscription/RevenusEtBanque.vue";
import ChoixClient from "@/views/souscription/ChoixClient.vue";
import Justificatories from "@/views/souscription/Justificatories.vue";
import JustificatoriesDocuments from "@/views/souscription/JustificatoriesDocuments.vue";
import ReponseDePrincipe from "@/views/souscription/ReponseDePrincipe.vue";
import ReponseDePrincipeClose from "@/views/souscription/ReponseDePrincipeClose.vue";
import ReponseDePrincipeEnd from "@/views/souscription/ReponseDePrincipeEnd.vue";
import Breadcrumb from "@/components/complex-components/breadcrumb.vue";
import AppHeader from '@/components/headers/AppHeader.vue';
import PageHeader from '@/components/headers/PageHeader.vue';
import {SHOP, SUBSCRIPTION} from "@/store/classes/PageHeaderText";
import NavigationAcrossSubscription from "@/components/navigations/navigation-across-subscription.vue";
import {Prop} from "vue-property-decorator";
import Loader from "@/components/loader/loader.vue"
import TabletteHeader from "@/components/headers/TabletteHeader.vue";
import Foot from "@/components/complex-components/footer.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import Checkbox from "@/components/inputs/checkbox.vue";
import clientChoiceState from "@/store/modules/subscription/clientChoiceState";
import simulationState from "@/store/modules/subscription/simulationState";
import InformationsPrecontractuellesWrapper from "@/views/modals/InformationsPrecontractuellesWrapper.vue";
import informationState from "@/store/modules/subscription/informationState";
import DesignTable from "@/components/complex-components/design-table.vue";
import defaultJustificatoryState from "@/store/modules/subscription/defaultStates/defaultJustificatoryState";
import justificatoryState from "@/store/modules/justificatoryState";
import incomeAndBankState from '@/store/modules/subscription/incomeAndBankState';
import {standardDateToFrenchDate} from "@/utils/misc";
import AdditionalJustificatoriesDocuments from "@/views/souscription/AdditionalJustificatoriesDocuments.vue";
import File from "@/components/inputs/file.vue";



// let informationState = namespace('informationState');

@Component({
    components: {
      AdditionalJustificatoriesDocuments,
        AppHeader,
        TabletteHeader,
        PageHeader,
        DropMenuWrapper,
        Breadcrumb,
        ReponseDePrincipe,
        ReponseDePrincipeClose,
        ReponseDePrincipeEnd,
        ChoixClient,
        Checkbox,
        RevenusEtBanque,
        Situation,
        Informations,
        Simulation,
        Justificatories,
        JustificatoriesDocuments,
        InformationsPrecontractuellesWrapper,
        NavigationAcrossSubscription,
        Loader,
        DesignTable,
        Foot,
        File
    },
    watch: {
        currentTextHeader: {
            immediate: true,
            handler(newVal, oldVal) {
                this.updateHeaderOfPageOnChange(newVal)
            }
        }
    }
})
export default class Souscription extends Vue implements ContextAndHeaderManager {
    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;
  // @informationState.State('coBorrowerSign') coBorrowerSign: boolean;

  @Getter('simulationState/getLabelOfSelectedProduct') getLabelOfSelectedProduct;
  @Getter('simulationState/isScaleWithFee') isScaleWithFee;
  @Getter('situationState/getProfessionOfBorrower') getProfessionOfBorrower;
  @Getter('informationState/getFamilySituationOfBorrower') getFamilySituationOfBorrower;


  @Mutation('clientChoiceState/setBorrowerPrestation') setBorrowerPrestation;
  @Mutation('clientChoiceState/setCoBorrowerPrestation') setCoBorrowerPrestation;
  @Mutation('clientChoiceState/setCreditCard') setCreditCard;
    bundle = {};

  @Prop({default: 0})
  targetPage;

  choixClient = clientChoiceState.state;
  simulation = simulationState.state;
  information = informationState.state;
  justificatory = justificatoryState.state;
  incomeAndBank = incomeAndBankState.state;


  currentPage = 0;
  qrCodeIsOpen = false;
  ModalValidateIsOpen = false;
  showdialogue = false;
  acceptedConditions = false;
  acceptedConditionsSE = false;
  acceptedConditionsSE1 = false;
  acceptedConditionsSE2 = false;
  acceptedConditionsSE3 = false;
  acceptedConditionsSE4 = false;
  acceptedConditionsSE5 = false;
  SMSContract = '';
  right = {text: "Confirmer", callback: this.footerRightCallback};
  rightEnd = {text: "Terminer", callback: this.onNextButtonPressed};
  rightDialogue = {text: "Confirmer", clickable: this.isClickable, callback: this.toggleAcceptation};
  rightDialogueSE = {text: "Confirmer", clickable: this.isClickableSE, callback: this.footerRightCallback};
  leftDialogueSE = {text: "Envoyer par email", clickable: false, callback: () => {}};
  currentSlot = 0;
  footerRight : 'footerClass';
  commercialOffersAgreement = false;
  noProspection = false;


  datatable = [
    ['Tranches', 'Taux débiteur révisable', 'TAEG révisable'],
    ['De 0,00 € à 3 000,00 €', '9,477 %', '9,900 %'],
    ['De 3 000,01 € à 6 000,00 €', '9,477 %', '9,900 %'],
    ['A partir de 6 000,01 €', '5,366 %', '5,500 %']
  ];

  datatable2 = [
    ['', 'Avant souscription', 'Après souscription'],
    ['Crédit(s)', '500,00 €', '597,00 €'],
    ['Charge(s)', '600,00 €', '600,00 €'],
    ['Reste à vivre', '9 400,00 €', '9 303,00 €']
  ];
    slotsManager = new SlotsManagerImpl();

    currentTextHeader = SUBSCRIPTION;

    pages = [
        {
            title: 'Simulation',
            icon: 'img/simulation.png',
            template: 'simulation',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'justificatories',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'informations',
            header: SUBSCRIPTION
        },
        {
            title: 'Situation',
            icon: 'img/situation.png',
            template: 'situation',
            header: SUBSCRIPTION
        },
        {
            title: 'Revenus et banque',
            icon: 'img/revenus-et-banque.png',
            template: 'revenus-et-banque',
            header: SUBSCRIPTION
        },
        {
            title: 'Choix client',
            icon: 'img/choix-client.png',
            template: 'choix-client',
            header: SHOP
        },
        {
            title: 'Réponse de principe',
            icon: 'img/reponse-de-principe.png',
            template: 'reponse-de-principe',
            header: SHOP
        },
        {
            title: 'Finalisation de la SE',
            icon: 'img/reponse-de-principe.png',
            template: 'reponse-de-principe-close',
            header: SHOP
        },
        {
            title: 'Succès de la SE',
            icon: 'img/reponse-de-principe.png',
            template: 'reponse-de-principe-end',
            header: SHOP
        },
    ];
    pagesBreadcrum = [
        {
            title: 'Simulation',
            icon: 'img/simulation.png',
            template: 'simulation',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'justificatories',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'informations',
            header: SUBSCRIPTION
        },
        {
            title: 'Situation',
            icon: 'img/situation.png',
            template: 'situation',
            header: SUBSCRIPTION
        },
        {
            title: 'Revenus et banque',
            icon: 'img/revenus-et-banque.png',
            template: 'revenus-et-banque',
            header: SUBSCRIPTION
        },
        {
            title: 'Choix client',
            icon: 'img/choix-client.png',
            template: 'choix-client',
            header: SHOP
        },
        {
            title: 'Réponse de principe',
            icon: 'img/reponse-de-principe.png',
            template: 'reponse-de-principe',
            header: SHOP
        },
    ];

    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

  get isClickable() {
    return this.acceptedConditions;
  }

  get isClickableSE() {
    return this.acceptedConditionsSE;
  }

  get isClickableSEAll() {
    return (this.acceptedConditionsSE1 && this.acceptedConditionsSE2 && this.acceptedConditionsSE3 && this.acceptedConditionsSE4 && this.acceptedConditionsSE5);
  }

  get isClickableSMS() {
    return this.SMSContract !== '';
  }

  get completeName() {
    if(this.information.borrower.firstName == "" && this.information.borrower.name == "")
      return "inconnu";

    return this.information.borrower.firstName+' '+this.information.borrower.name;
  }

  get genderAndName() {
    let text = this.information.borrower.gender+" "+this.information.borrower.firstName+' '+this.information.borrower.name;

    if(RegExp(/ {2}/).test(text)) {
      text = "Etat civil";
    }

    return text;
  }

  get address() {
    return (this.information.contactInformation.address!="" ? this.information.contactInformation.address+" " : "")
        + this.information.contactInformation.complementAddress;
  }

  get zipCodeAndTown() {
    let text = (this.information.contactInformation.zipCode!="" ? this.information.contactInformation.zipCode+" " : "")
        + this.information.contactInformation.city;

    if (!RegExp(/\w/).test(text))
      text = "Adresse";

    return text;
  }

  get situationAndNumberOfChildren() {
    let situation = this.information.borrower.familySituation == ""?"Situation":this.getFamilySituationOfBorrower;
    let nbChildren = this.information.borrower.dependentChildren?this.information.borrower.dependentChildren+" enfant(s)":"";

    return situation+" "+nbChildren;
  }

    created() {
        this.updateHeaderOfPageOnChange(this.currentTextHeader);
        this.currentPage = this.targetPage;
    }

    updateCurrentSlot(value){
        this.currentSlot = value;
    }
    onNextTabletButtonPressed(bundle){

      if (this.qrCodeIsOpen) {
        this.information.mobileVersion = true;
        this.currentPage++;
      }
    }

    get isNotVAC() {
      return this.simulation.selectedOffer != "CREDIT";
    }
    get creditAmount() {
      return parseFloat(this.simulation.totalAmount).toFixed(2);
    }
    get borrowerPrestationChoiceSynthesis() {
    if (this.choixClient.borrowerPrestation) {
      return "J'adhère à l'assurance facultative, et bénéficierai des garanties\n" +
          "                    'DC INVALIDITE ITT EMPRUNTEUR / Assistance vie quotidienne'";
    } else {
      return "Je n'adhère pas à l'assurance facultative, et ne bénéficierai pas des garanties\n" +
          "                    'DC INVALIDITE ITT EMPRUNTEUR / Assistance vie quotidienne'";
    }
  }

    get coBorrowerPrestationChoiceSynthesis() {
    if(this.choixClient.coBorrowerPrestation) {
      return "J'adhère à à l'assurance facultative, et bénéficierai des garanties\n" +
          "                    'Décès/Invalidité/Maladie T2'";
    }
    else {
      return "Je n'adhère pas à l'assurance facultative, et ne bénéficierai pas des garanties\n" +
          "                    'Décès/Invalidité/Maladie T2'";
    }
  }

    get monthlyPayment() {
      return parseFloat(this.simulation.monthlyPayment).toFixed(2);
    }

    get lastPayment() {
      let totalAmount = parseFloat(this.amount_credit());
      return (totalAmount - ((parseInt(this.simulation.echeance) - 1) * parseFloat(this.monthlyPayment))).toFixed(2).replace('.',',');
    }



    amount_credit() {
      if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount != '') {
        let purchaseAmount = parseFloat(this.simulation.purchaseAmount);
        let bringAmount = parseFloat(this.simulation.bringAmount);

        return (purchaseAmount - bringAmount).toFixed(2);
      } else {
        if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount == '')
          return this.simulation.purchaseAmount;
        else
          return ('0.00')
      }
    }
    onNextButtonPressed(bundle) {
        this.bundle = bundle;

        // this.currentPage = (this.currentPage + 1) % this.pages.length;

        if (this.currentPage >= (this.pages.length - 1)) {
            this.$router.push('/esign/1');
        } else {
            this.currentPage++;
        }
    }

    onConfirm() {
      this.$emit('confirm')
    }

    changeQrCode(){
        this.qrCodeIsOpen = !this.qrCodeIsOpen;
    }
    changeModalValidate(){
      this.ModalValidateIsOpen = !this.ModalValidateIsOpen;
    }
    showDialogueFiche(value){
      this.showdialogue = value;
    }
    goToPage(index: number) {
        this.currentPage = index;
        this.currentSlot = 0;
    }
    showAmount(amount: string) {
      if (amount == null || amount == "")
        return "0,00";

      return amount.replace(".",",");
    }
    goToNextDropMenu() {
      this.slotsManager.incrementTheCounter();
    }


    footerRightCallback() {
      if(this.currentPage == 5 && this.currentSlot == 1){
        this.currentSlot += 1;
        this.$emit('updateSlot', 2);
      } else if(this.currentPage == 4 && this.showdialogue){
          this.showdialogue = false;
        this.$emit('showCCSWrapper');
      } else if(this.currentPage == 6 && this.justificatory.borrowerIdItems[0]['validated'] && this.justificatory.borrowerIdItems[1]['validated'] && this.justificatory.ribItems[0]['validated'] && this.justificatory.domJustItems[0]['validated']) {
        this.currentPage += 1;
        this.currentSlot = 1;
        this.justificatory.borrowerIdItems[0]['validated'] = false;
        this.justificatory.borrowerIdItems[1]['validated'] = false;
        this.justificatory.ribItems[0]['validated'] = false;
        this.justificatory.domJustItems[0]['validated'] = false;
      }else if (this.currentPage == 7 && this.currentSlot == 1 && this.acceptedConditionsSE){
        this.currentSlot += 1;
        this.toggleAcceptationSE();
      }else if (this.currentPage == 7 && this.currentSlot == 2 && this.isClickableSEAll){
        this.currentSlot += 1;
        this.toggleAcceptationSE1();
        this.toggleAcceptationSE2();
        this.toggleAcceptationSE3();
        this.toggleAcceptationSE4();
        this.toggleAcceptationSE5();
      }else if (this.currentPage == 7 && this.currentSlot == 3 && this.isClickableSMS){
        this.SMSContract = '';
        this.currentSlot += 1;
      }else if (this.currentPage == 7 && this.currentSlot == 4 && this.isClickableSMS){
        this.SMSContract = '';
        this.currentPage += 1;
      }else if (this.currentPage === 8){
        this.SMSContract = '';
        this.currentPage += 1;
      }
      else{
        if(this.justificatory.borrowerIdItems[0]['validated'] && this.justificatory.borrowerIdItems[1]['validated'] && this.justificatory.ribItems[0]['validated'] && this.currentPage !== 6){
          // this.currentPage += 1;
          this.$emit('incrementSlot', 1);
          // this.justificatory.borrowerIdItems[0]['validated'] = false;
          // this.justificatory.borrowerIdItems[1]['validated'] = false;
          // this.justificatory.ribItems[0]['validated'] = false;
        }
      }
    }

  standardDateToFrenchDate(date) {
    return standardDateToFrenchDate(date);
  }

  toggleCommercialOffersAgreement() {
    this.commercialOffersAgreement = !this.commercialOffersAgreement;
  }

  toggleAcceptation() {
    this.acceptedConditions = !this.acceptedConditions;
  }

  toggleAcceptationSE() {
    this.acceptedConditionsSE = !this.acceptedConditionsSE;
  }
  toggleAcceptationSE1() {
    this.acceptedConditionsSE1 = !this.acceptedConditionsSE1 ;
  }
  toggleAcceptationSE2() {
    this.acceptedConditionsSE2 = !this.acceptedConditionsSE2 ;
  }
  toggleAcceptationSE3() {
    this.acceptedConditionsSE3 = !this.acceptedConditionsSE3 ;
  }
  toggleAcceptationSE4() {
    this.acceptedConditionsSE4 = !this.acceptedConditionsSE4 ;
  }
  toggleAcceptationSE5() {
    this.acceptedConditionsSE5 = !this.acceptedConditionsSE5 ;
  }

  toggleNoProspection() {
    this.noProspection = !this.noProspection;
  }
}

applyMixins(Souscription, [SubscriptionContextAndHeaderManagerImpl]);

