/**
 * generate a unique id given a collection
 * @param collection a collection of objects that contains an id key
 * @returns {number} a unique id
 */
export const generateUniqueId = (collection : Array<{id:number}>) : number => {
    let id = Math.floor(Math.random()*10000);
    while(collection.find(item => item.id == id))
        id = Math.floor(Math.random()*10000);
    return id;
};