import {BaseValidatorRule} from "../BaseValidatorRule";

export class RequiredRule extends BaseValidatorRule {

    getDefaultErrorMessage(): string {
        return "Champs obligatoire";
    }

    validate(value: any): boolean {
        let valid = false;

        if (typeof value == "string")
            valid = value !== "" && value != null;
        else if (value instanceof Array)
            valid = value.length > 0;
        else
            valid = value != null;

        this.hasPassed = valid;
        return valid;
    }
}