const state = {
    /**
     * The number of promise we are waiting to finish
     */
    nbWaitingTasks : 0
};


// actions
const actions = {
    /**
     * Increment the number of waiting task while the promise is resolving,
     * So we can display a loader with the loading displayer component
     * @param state
     * @param commit
     * @param promise
     * @returns {Promise<T>}
     */
    load ({commit},promise){
        return new Promise( (resolve,reject) => {
                commit('incrementNbWaitingTasks');
                promise.then(
                    promiseReturn => {
                        commit('decrementNbWaitingTasks');
                        resolve(promiseReturn);
                    }
                ).catch(
                    promiseError => {
                        commit('decrementNbWaitingTasks');
                        reject(promiseError);
                    }
                );
        });
    }
};

// mutations
const mutations = {
    /**
     * increment by one the number of waiting tasks
     * @param state
     */
    incrementNbWaitingTasks (state){
        state.nbWaitingTasks++
    },
    /**
     * increment by one the number of waiting tasks
     * @param state
     */
    decrementNbWaitingTasks (state){
        state.nbWaitingTasks--
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
