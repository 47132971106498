import { render, staticRenderFns } from "./AdditionalJustificatories.vue?vue&type=template&id=76113741&scoped=true&"
import script from "./AdditionalJustificatories.vue?vue&type=script&lang=ts&"
export * from "./AdditionalJustificatories.vue?vue&type=script&lang=ts&"
import style0 from "./AdditionalJustificatories.vue?vue&type=style&index=0&id=76113741&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76113741",
  null
  
)

export default component.exports