import { render, staticRenderFns } from "./Situation.vue?vue&type=template&id=e8d53e72&scoped=true&"
import script from "./Situation.vue?vue&type=script&lang=ts&"
export * from "./Situation.vue?vue&type=script&lang=ts&"
import style0 from "./Situation.vue?vue&type=style&index=0&id=e8d53e72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8d53e72",
  null
  
)

export default component.exports