





















































import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import {assetImg} from "@/utils/misc";

@Component({})
export default class Datatable extends Vue {

    @Prop({default: []})
    headers: Array<any>;
    @Prop({default: []})
    entries: Array<any>;
    @Prop()
    totalPages: number;
    @Prop()
    currentPage: number;
    @Prop()
    hasNext: boolean;
    @Prop()
    hasPrevious: boolean;
    @Prop()
    isHistory: boolean;
    @Prop()
    headerInversed: boolean;
    @Prop()
    headerOranged: boolean;


    get paginateIndexes() {
        let beginIndex = this.currentPage < 5 ? 1 : this.currentPage - 3;
        let endIndex;
        if (this.totalPages < 5 || this.totalPages == this.currentPage)
            endIndex = this.totalPages;
        else if (this.currentPage >= 5)
            endIndex = this.currentPage + 1;
        else
            endIndex = 5;
        let indexesArray = [];
        for (let i = beginIndex; i <= endIndex; i++)
            indexesArray.push(i);
        return indexesArray;

    }


    rowClicked(key, entry) {
        this.$emit('rowClicked', {key: key, entry});
    }

    colClicked(key, value) {
        if(RegExp("<button.*>.*</button>").test(value)) {
            this.$emit('clickButton', key)
        }
    }

    goTo(paginateIndex) {
        this.$emit('goToPage', paginateIndex);
    }

    first() {
        if (this.hasPrevious)
            this.$emit('goToFirstPage');
    }

    previous() {
        if (this.hasPrevious)
            this.$emit('goToPreviousPage');
    }

    next() {
        if (this.hasNext)
            this.$emit('goToNextPage');
    }

    last() {
        if (this.hasNext)
            this.$emit('goToLastPage');
    }

    isBreakWordCol(key) {
        return [].indexOf(key) != -1;
    }

    sort(sortKey) {

    }

    image(path: string) {
        return assetImg(path);
    }

}
