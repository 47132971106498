import getDefaultJustificatoryState from "@/store/modules/subscription/defaultStates/defaultJustificatoryState";

const state: { coBorrowerIdItems: Array<any>, borrowerIdItems: Array<any>, ribItems: Array<any>, domJustItems: Array<any>} = getDefaultJustificatoryState()

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    resetDefaultState(state) {
        Object.assign(state, getDefaultJustificatoryState())
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
