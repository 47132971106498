export default class BrowserInfos {
    name: string;
    version: number;

    constructor(name: string, version: number) {
        this.name = name;
        this.version = version;
    }

    isGridDisplayUsable(): boolean {
        switch (this.name) {
            case "Chrome":
                return this.version >= 57;
            case "Firefox":
                return this.version >= 52;
            case "IE":
                return this.version >= 10;
            case "Opera":
                return this.version >= 44;
            default:
                return true;
        }
    }
}