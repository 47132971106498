<!--suppress CheckTagEmptyBody -->
<template>
    <div id="app">
        <notification-displayer></notification-displayer>



        <router-view/>

    </div>
</template>

<script>


    import NotificationDisplayer from '@/components/displayers/notification-displayer';

    export default {
        components: {NotificationDisplayer},

        created() {
            this.$store.commit('setBrowserInfo');
        }
    };

</script>

<style lang="scss">
    @import '../assets/sass/header';

  #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
  }

  #nav {
      padding: 30px;

      a {
          font-weight: bold;
          color: #2c3e50;

          &.router-link-exact-active {
            color: #42b983;
          }
      }
  }

  .gap-between-elements {
    margin: 16px 0;
  }

    .side-margins {
        margin: 8px;
    }

</style>
