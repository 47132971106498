import Vue from 'vue'
import Vuex from 'vuex'
import loader from "@/store/modules/loader";
import notifications from "@/store/modules/notifications";
import popups from "@/store/modules/popups";
import exportModule from "@/store/modules/exportModule";
import subscriptionState from "@/store/modules/subscriptionState";
import validation from "@/store/modules/validation";
import htmlFeatures from "@/store/modules/htmlFeatures";
import shopData from "@/store/modules/shopData";
import {versionAndNameOfBrowser} from "@/utils/misc";
import BrowserInfos from "@/typings/BrowserInfos";
import simulationState from "@/store/modules/subscription/simulationState";
import informationState from "@/store/modules/subscription/informationState";
import situationState from "@/store/modules/subscription/situationState";
import incomeAndBankState from "@/store/modules/subscription/incomeAndBankState";
import justificatoryState from "@/store/modules/justificatoryState";
import clientChoiceState from "@/store/modules/subscription/clientChoiceState";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    browserInfo: new BrowserInfos("", 0)
  },
  getters: {
    getBrowserInfo(state) {
      return state.browserInfo;
    },
    canUseGridDisplay(state) {
      return state.browserInfo.isGridDisplayUsable();
    }
  },
  mutations: {
    setBrowserInfo(state) {
      state.browserInfo = versionAndNameOfBrowser();
    }
  },
  actions: {
  },
  modules: {
    exportModule: exportModule,
    htmlFeatures: htmlFeatures,
    loader: loader,
    notifications: notifications,
    popups: popups,
    shopData: shopData,
    subscriptionState: subscriptionState,
    validation: validation,
    simulationState: simulationState,
    informationState: informationState,
    situationState: situationState,
    incomeAndBankState: incomeAndBankState,
    clientChoiceState: clientChoiceState,
    justificatoryState: justificatoryState,
  }
})
