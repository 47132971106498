



























import {Vue} from "vue-class";
import Component from "vue-class-component";
import BasicModal from "../../components/modals/basic-modal.vue";
import {Prop} from "vue-property-decorator";

@Component({
    components: {BasicModal}
})
export default class InformationAdvices extends Vue {

    @Prop()
    isOpened: boolean;


    onClose() {
        this.$emit('close');
    }

    onConfirm() {
        this.$emit('confirm');
    }
}
