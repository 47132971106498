








import Vue from 'vue'
import Component from 'vue-class-component'
import {Notification, NotificationStatus, NotificationType} from "@/typings/Notifications";
import {namespace} from "vuex-class";
import {Mutation} from "vuex-class";

const notifications = namespace('notifications');

@Component({})
export default class NotificationDisplayer extends Vue {

    @notifications.State('notifications') notifications: [Notification];
    @Mutation('notifications/deleteNotification') deleteNotification;

    TOAST_TIMEOUT = 5000;

    currentNotifications: {[key: string]: Notification} = {};
    timers: {[key: string]: any} = [];


    mounted() {
        this.$watch('notifications', (notifications)=>{
            notifications.forEach((notification) => {
                this.addNotificationToList(notification);
            });
        });
    }

    addNotificationToList(notification: Notification) {
        if(!this.currentNotifications["notification"+notification.id]) {
            Vue.set(this.currentNotifications, "notification"+notification.id, notification);
            if(notification.type == NotificationType.TOAST) {
                this.timers["notification"+notification.id] = this.setToastTimer(notification);
            }
        }

    }

    /**
     * Deletes the notification when TOAST_TIMEOUT duration is reached
     */
    setToastTimer(notification: Notification) {
        setTimeout(()=> {
            this.deleteNotification(notification);
            Vue.delete(this.currentNotifications,"notification"+notification.id);
            delete this.timers["notification"+notification.id];
        }, this.TOAST_TIMEOUT);
    }

    /**
     * Select the notification theme, given its status
     * @param notification
     */
    selectTheme(notification: Notification) {
        let theme = "info";
        switch (notification.status) {
            case NotificationStatus.ERROR :
                theme = "error";
                break;
            case NotificationStatus.SUCCESS :
                theme = "success";
                break;
            default :
                theme = "info";
                break;
        }
        return theme;
    }

    /**
     *
     */
    deleteByClick(notification) {
        this.deleteNotification(notification)
    }

}
