





















import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import SectionTitle from "@/components/displayers/section-title.vue";
import Radio from "@/components/inputs/radio.vue";
import {Prop} from "vue-property-decorator";

@Component({
    components: {Radio, SectionTitle, DropMenuWrapper}
})
export default class AvisFavorable extends Vue {

    @Prop()
    bundle;

    slotsManager = new SlotsManagerImpl();

    releveDeCompteDematerialise = "";
    releveDeCompteDematerialiseChoices = [{label: "Oui", value: "Yes"}, {label: "Non", value: "No"}];

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextPage() {
        this.$emit("nextPage");
    }
}

