import getDefaultIncomeAndBankState from "@/store/modules/subscription/defaultStates/defaultIncomeAndBankState";

const state: { borrower: any, coBorrower: any, bankInformation: any } = getDefaultIncomeAndBankState();

// getters
const getters = {};

// actions
const actions = {
    setEmptyIncomeAndBankData(state) {
        let borrowerValue = ['netIncomeExceptedFees', 'familyIncome', 'otherIncomes', 'rentsOrRealEstateReimbursement', 'otherCredits', 'otherCharges'];
        let coBorrowerValue = ['coBorrowerNetIncomeExceptedFees', 'coBorrowerOtherIncomes'];

        borrowerValue.forEach((key) => {
            state.commit('setBorrowerIncome', {key: key, value: ''});
        });
        coBorrowerValue.forEach((key) => {
            state.commit('setCoBorrowerIncome', {key: key, value: ''});
        });
    },

    setEmptyBankData(state) {
        let bankInformationValue = ['rib', 'bic', 'iban', 'accountOwner', 'bankName', 'seniority'];

        bankInformationValue.forEach((key) => {
            state.commit('setBankInformation', {key: key, value: ''});
        });
    }
};

// mutations
const mutations = {
    setBorrowerIncome(state, value: { key: string, value: string }) {
        state.borrower[value.key] = value.value;
    },
    setCoBorrowerIncome(state, value: { key: string, value: string }) {
        state.coBorrower[value.key] = value.value;
    },
    setBankInformation(state, value: { key: string, value: string }) {
        state.bankInformation[value.key] = value.value;
    },
    resetDefaultState(state) {
        Object.assign(state, getDefaultIncomeAndBankState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
