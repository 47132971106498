












import Component from "vue-class-component";
import {Vue} from "vue-class";
import {Prop} from "vue-property-decorator";

@Component({})
export default class BasicModal extends Vue {

    @Prop({default: ''})
    componentStyle: string;

    @Prop({default: false})
    isOpened;

    blur = false;

    onNothing() {
        this.blur = false;
    }

    onBlur() {
        if (this.blur) {
            this.$emit('close');
        }
        this.blur = true;
    }

}

