

































import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import CustomInput from "./custom-input"

@Component({
    filters: {
        addSlashesToDate: (dateString: string) => {
            return dateString.split("-").reverse().join("/");
        }
    }
})
export default class DateInput extends CustomInput {

    @Prop({default: 'false'})
    required: string;

    @Prop({default: false})
    justShow: boolean;

    @Prop({default: false})
    disabled: boolean;

    @Prop({default: 'left'})
    align: string;

    @Prop({default: false})
    monthOnly: boolean;

    @Prop()
    suffix: string;

    inputType = "text";
    inputmode = "";
    textAlignment = this.align;

    mounted() {
        if (this.required == 'true' && this.label !== '') {
            this.asterisk = ' *';
        }
        if (this.justShow) {
            this.textAlignment = "right";
        }
    }

    emitValue(dateString: string) {
        let rValue = dateString.split("/").reverse().join("-");

        if (dateString.length == 2 || (!this.monthOnly && dateString.length == 5)) {
            rValue = "-" + dateString;
        }

        this.$emit('input', rValue)
    }

}

