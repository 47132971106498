const FINANCIAL_MAX_ITERATIONS = 128;
const FINANCIAL_PRECISION = 1.0e-08;

const monthlyPayment = (rate, duration, loan) => {

    let monthRate = rate / 1200;
    let coef = Math.pow(1. + monthRate, duration);

    return Math.round((loan) * monthRate * coef * 100 / (coef - 1)) / 100;
};

const financialRate = (duration, monthlyPayment, totalAmount, fv = 0.0, type = 0, guess = 0.1) => {

    let rate = guess;
    let f = 0;

    if (Math.abs(rate) >= FINANCIAL_PRECISION) {
        f = Math.exp(duration * Math.log(1 + rate));
    }

    let y0 = totalAmount + monthlyPayment * duration + fv;
    let y1 = totalAmount * f + monthlyPayment * (1 / rate + type) * (f - 1) + fv;
    let i = 0.0;
    let x0 = 0.0;
    let x1 = rate;

    while ((Math.abs(y0 - y1) > FINANCIAL_PRECISION) && (i < FINANCIAL_MAX_ITERATIONS)) {

        rate = (y1 * x0 - y0 * x1) / (y1 - y0);
        x0 = x1;
        x1 = rate;

        if ((duration * Math.abs(monthlyPayment)) > (totalAmount - fv)) {
            x1 = Math.abs(x1);
        }

        let y = 0;

        if (Math.abs(rate) < FINANCIAL_PRECISION) {
            y = totalAmount * (1 + duration * rate) + monthlyPayment * (1 + rate * type) * duration + fv;
        } else {
            f = Math.exp(duration * Math.log(1 + rate));
            y = totalAmount * f + monthlyPayment * (1 / rate + type) * (f - 1) + fv;
        }

        y0 = y1;
        y1 = y;
        i++;
    }

    return rate;
};

const calculateAPR = (tauxAnnuelDebiteurFixe, duration, totalAmount, fees) => {
    const monthlyPaymentResult = monthlyPayment(tauxAnnuelDebiteurFixe, duration, totalAmount + fees);
    return financialRate(duration, -monthlyPaymentResult, totalAmount) * 12;
};

export default class APRCalculator {

    static calculate = calculateAPR;
    static monthlyPayment = monthlyPayment;
}
