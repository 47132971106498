









import Vue from 'vue'
import {assetImg} from "@/utils/misc";
import Component from "vue-class-component";

@Component
export default class HeaderDisconnection extends Vue {
    image(path: string) {
        return assetImg(path);
    }
}

