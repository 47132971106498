

























import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import File from "@/components/inputs/file.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import {Prop} from "vue-property-decorator";
import NotificationDisplayer from "@/components/displayers/notification-displayer.vue";

@Component({
    components: {NotificationDisplayer, BasicModal, File, DropMenuWrapper}
})
export default class ModalSendMail extends Vue {

    @Prop({default: false})
    isOpened: boolean;

    onConfirm() {
        this.$emit('confirm');
    }

    onClose() {
        this.$emit('close')
    }
}

