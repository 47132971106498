import BrowserInfos from "@/typings/BrowserInfos";

export function urlToAssets()
{
    //TODO make .env working
    //noinspection TypeScriptUnresolvedVariable
    // let rootUrl = process.env.MIX_SELF_URL;
    // let protocol = process.env.MIX_ENVIRONMENT == 'dev' ? 'http://' : 'https://';
    // if(rootUrl[rootUrl.length-1]!='/')
    //     rootUrl+='/';

    // let protocol = "http://";
    // let rootUrl = "localhost:8080/";
    // return protocol + rootUrl;
    return '';
}

export function assetImg(path) {
    return urlToAssets() + 'img/' + path;
}

export function assetFile(path) {
    return urlToAssets() + 'files/' + path;
}

export function asset(path) {
    return urlToAssets()+path;
}

/**
 * Reformate the price in the standard format : ...nnn,nn €
 * @param price
 * @return the formatted string
 */
export function fullPriceWithDevise(price) {
    let strprice = "";
    if(typeof price === "string") {
        strprice = Number(price).toFixed(2) + " €";
    }
    if(typeof price === "number") {
        strprice = price.toFixed(2)+" €";
    }
    return strprice;
}

export function snakeToCamel(s){
    return s.replace(/(-\w)/g, function(m){return m[1].toUpperCase();});
}

export function truncateLongString(str: string) {
    return str.length>20?str.slice(0,17).concat("..."):str
}

export function isEmptyObject(object: Object): boolean {
    return Object.keys(object).length == 0
}

export function versionAndNameOfBrowser() {
    let ua= navigator.userAgent, tem,
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];

        return new BrowserInfos("IE", parseInt(tem[1]) || -1);
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) {
            tem = tem.slice(1);
            tem[1].replace('OPR', 'Opera');

            return new BrowserInfos(tem[0], parseInt(tem[1]));
        }
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);

    return new BrowserInfos(M[0], parseInt(M[1]));
}

export function standardDateToFrenchDate(date) {
    return date.split("-").reverse().join("/");
}