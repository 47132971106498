var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.footerClass},[(_vm.leftButton.show == true || _vm.leftButton.show == undefined)?_c('button',{staticClass:"btn-component button-left",class:{
                'c-easy-button-white-outlined': _vm.leftButton.inversed,
                 'clickable': _vm.leftButton.clickable === undefined || _vm.leftButton.clickable,
                  'not-clickable': !(_vm.leftButton.clickable === undefined || _vm.leftButton.clickable)
            },on:{"click":function($event){_vm.leftButton.clickable === undefined || _vm.leftButton.clickable ? _vm.leftButton.callback() : null}}},[_vm._v(" "+_vm._s(_vm.leftButton.text)+" ")]):_vm._e(),(_vm.rightButton.show == true || _vm.rightButton.show == undefined)?_c('button',{staticClass:"btn-component button-right",class:{
                'c-easy-button-white-outlined': _vm.rightButton.inversed,
                 'clickable': _vm.rightButton.clickable === undefined || _vm.rightButton.clickable,
                  'not-clickable': !(_vm.rightButton.clickable === undefined || _vm.rightButton.clickable)
            },on:{"click":function($event){_vm.rightButton.clickable === undefined || _vm.rightButton.clickable ? _vm.rightButton.callback() : null}}},[_vm._v(" "+_vm._s(_vm.rightButton.text)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }