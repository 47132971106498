import {BaseValidatorRule} from "../BaseValidatorRule";

export class NumberRule extends BaseValidatorRule {

    ruleForNumber = /^(?:-?([0-9]+\.?[0-9]*|[0-9]*\.?[0-9]+))$/

    getDefaultErrorMessage(): string {
        return "Nombre invalide";
    }

    validate(value: string): boolean {
        return value == "" || value == null || RegExp(this.ruleForNumber).test(value);
    }

}