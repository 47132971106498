







import Vue from 'vue'
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class HeaderText extends Vue {

    @Prop({
        type: String,
        default: ""
    })
    text;

    @Prop({
        type: String,
        default: "black"
    })
    fontColor;

    @Prop({
        type: String,
        default: "1em"
    })
    fontSize;

    @Prop({
        type: String,
        default: "normal"
    })
    fontWeight;

    @Prop({
        type: String,
        default: "0"
    })
    topSpace;

    @Prop({
        type: String,
        default: "0"
    })
    leftSpace;

    styleOfText = {
        color: this.fontColor,
        'font-size': this.fontSize,
        'font-weight': this.fontWeight,
        'padding-left': this.leftSpace,
    };
}

