













import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";

@Component({})
export default class Checkbox extends Vue {

    @Prop()
    title: string;

    @Prop({default: false})
    smallTitle: boolean;

    @Prop({default: false})
    extraSmallTitle: boolean;

    @Prop()
    name: string;

    @Prop()
    value: string;


    emitFromChange(event) {
        this.$emit('input', event.target.checked)
    }
}
