

















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import PDFJS from 'pdfjs-dist/build/pdf.js';

// Le décorateur @Component indique que la classe est un composant Vue
@Component({
    // Toutes les options de composant sont autorisées ici.
})
export default class File extends Vue {
    @Prop()
    path: string;

    @Prop()
    height: string;

    mounted() {
        this.renderPDF(this.path);
    }

    renderPDF(url) {
        PDFJS.disableWorker = true;

        PDFJS.getDocument(url).then(this.renderPages);
    }

    renderPages(pdfDoc) {
        for (let num = 1; num <= pdfDoc.numPages; num++)
            pdfDoc.getPage(num).then(this.renderPage);
    }

    renderPage(page) {
        let shift = screen.width / 100 * 0.1;
        let viewport = page.getViewport(Math.max(0.9, shift));

        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let renderContext = {
            canvasContext: ctx,
            viewport: viewport,
        };

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        var PDFCanvas = this.$refs.canvasPDF as any;
        if(PDFCanvas)
            (PDFCanvas).appendChild(canvas);

        page.render(renderContext);
    }
}
