






import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({})
export default class NavigationAcrossSubscription extends Vue {

    @Prop({default: []})
    pages;


    emitPageIndex(index: number) {
        this.$emit("goToNthPage", index);
    }
}
