





























































































































































































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import SectionTitle from "@/components/displayers/section-title.vue";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import DesignTable from "@/components/complex-components/design-table.vue";
import Radio from "@/components/inputs/radio.vue";
import InformationsPrecontractuellesWrapper from "@/views/modals/InformationsPrecontractuellesWrapper.vue";
import ClientWarrantyOffers from "@/views/modals/ClientWarrantyOffers.vue";
import InformationAdvices from "@/views/modals/InformationAdvices.vue";
import Foot from "@/components/complex-components/footer.vue";
import {Getter, Mutation, namespace} from "vuex-class";
import clientChoiceState from "@/store/modules/subscription/clientChoiceState";
import simulationState from "@/store/modules/subscription/simulationState";
import APRCalculator from "@/utils/APRCalculator";
import InformationsOAAWrapper from "@/views/modals/InformationsOAAWrapper.vue";
import InformationDisplay from "@/components/displayers/information-display.vue";
import CreditOfferChoice from "@/views/modals/CreditOfferChoice.vue";
import moment from "moment";

let informationState = namespace('informationState');

@Component({
    components: {
      InformationAdvices,
      ClientWarrantyOffers,
      InformationsPrecontractuellesWrapper,
      InformationsOAAWrapper,
      InformationDisplay,
      CreditOfferChoice,
      DesignTable, Foot, SectionTitle, DropMenuWrapper, Radio
    },
})
export default class ChoixClient extends Vue {
    @informationState.State('coBorrowerSign') coBorrowerSign: boolean;

    @Getter('simulationState/getLabelOfSelectedProduct') getLabelOfSelectedProduct;
    @Getter('simulationState/isScaleWithFee') isScaleWithFee;


  @Mutation('clientChoiceState/setBorrowerPrestation') setBorrowerPrestation;
  @Mutation('clientChoiceState/setCoBorrowerPrestation') setCoBorrowerPrestation;
  @Mutation('clientChoiceState/setCreditCard') setCreditCard;

    choixClient = clientChoiceState.state;
    simulation = simulationState.state;

    right={text:"Confirmer", callback: this.footerRightCallback} ;

    // choice of insurance
    yesNoChoices = [{label: "OUI", value: true}, {label: "NON", value: false}];
    yesChoices = [{label: "OUI", value: true}];

    showDetail : boolean = false;

    datatable = [
        ['Tranches', 'Taux débiteur révisable', 'TAEG révisable'],
        ['De 0,00 € à 3 000,00 €', '9,477 %', '9,900 %'],
        ['De 3 000,01 € à 6 000,00 €', '9,477 %', '9,900 %'],
        ['A partir de 6 000,01 €', '5,366 %', '5,500 %']
    ];

    datatable2 = [
        ['', 'Avant souscription', 'Après souscription'],
        ['Crédit(s)', '500,00 €', '597,00 €'],
        ['Charge(s)', '600,00 €', '600,00 €'],
        ['Reste à vivre', '9 400,00 €', '9 303,00 €']
    ];

  onShowDetail(){
    this.showDetail = !this.showDetail;
  }
  getCurrentDate() {
    let date = moment();

    return date.format("DD/MM/YYYY");
  }
  getFinancementDate() {
    let date = moment();

    date.startOf("month")
        .add(2, "months")
        .add(9, "days");

    return date.format("DD/MM/YYYY");
  }
  createRecapDatatable() {
    let totalAmount: number;

    if (!this.isScaleWithFee) {
      totalAmount = parseFloat(this.amount_credit());
    } else {
      totalAmount = parseFloat(this.amount_credit()) + parseFloat(this.amount_credit()) * (this.simulation.APR / 100);
    }


    let datatable: any[];
    this.simulation.totalAmount = ""+totalAmount;

    datatable = [
      ['Montant de l\'achat en €', 'Montant de l\'apport en €', 'Montant du crédit en €'],
      [this.showAmount(this.simulation.purchaseAmount) + ' €', this.showAmount(this.simulation.bringAmount) + ' €', totalAmount.toFixed(2).replace('.', ',') + ' €'],
    ]

    return datatable;
  }

  created(){
    this.$parent.$on('updateSlot', this.goToNextDropMenu);
  }
  createDatatable() {
    let totalAmount: number;
    let deadlineAmount: number;
    let nbDeadline = parseInt(this.simulation.echeance);
    let lastDeadlineAmount: number;

    if (isNaN(nbDeadline) || nbDeadline == 0)
      nbDeadline = 1;

    if (!this.isScaleWithFee) {
      totalAmount = parseFloat(this.amount_credit());
      deadlineAmount = totalAmount / nbDeadline;
      lastDeadlineAmount = deadlineAmount;
    } else {
      totalAmount = parseFloat(this.amount_credit()) + parseFloat(this.amount_credit()) * (this.simulation.APR / 100);
      deadlineAmount = this.getMonthlyPayment();
      lastDeadlineAmount = totalAmount - ((nbDeadline - 1) * this.getMonthlyPayment());
    }
    this.simulation.monthlyPayment = ""+deadlineAmount;

    if (lastDeadlineAmount < 0) {
      deadlineAmount = totalAmount / nbDeadline;
      lastDeadlineAmount = totalAmount / nbDeadline;
    }

    // Set amounts to 0 if NaN
    deadlineAmount = (isNaN(deadlineAmount) ? 0 : deadlineAmount);
    lastDeadlineAmount = (isNaN(lastDeadlineAmount) ? 0 : lastDeadlineAmount);

    let strDeadlineAmount = deadlineAmount.toFixed(2).replace('.', ',');
    let strLastDeadlineAmount = lastDeadlineAmount.toFixed(2).replace(".", ",");

    let middleNbDeadline = Math.max(nbDeadline - 2, 0).toString();

    let datatable: any[];

    this.simulation.totalAmount = ""+totalAmount;

    if (this.choixClient.borrowerPrestation){
      if (nbDeadline == 2) {
        datatable = [
          ['', 'Avec Assurance'],
          ['1ère échéance de', strDeadlineAmount + ' €'],
          ['Suivi de', strLastDeadlineAmount + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      } else if (nbDeadline == 1) {
        datatable = [
          ['', 'Avec Assurance'],
          ['1ère échéance de', totalAmount.toFixed(2).replace('.', ',') + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      } else {
        datatable = [
          ['', 'Avec Assurance'],
          ['1ère échéance de', strDeadlineAmount + ' €'],
          ['Suivi de', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmount + ' €'],
          ['et une dernière ajustée de', strDeadlineAmount + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      }
    }
    else if (!this.choixClient.borrowerPrestation){
      if (nbDeadline == 2) {
        datatable = [
          ['', 'Hors Assurance'],
          ['1ère échéance de', strDeadlineAmount + ' €'],
          ['Suivi de', strLastDeadlineAmount + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      } else if (nbDeadline == 1) {
        datatable = [
          ['', 'Hors Assurance'],
          ['1ère échéance de', totalAmount.toFixed(2).replace('.', ',') + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      } else {
        datatable = [
          ['', 'Hors Assurance'],
          ['1ère échéance de', strDeadlineAmount + ' €'],
          ['Suivi de', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmount + ' €'],
          ['et une dernière ajustée de', strDeadlineAmount + ' €'],
          ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €'],
        ]
      }
    }

    return datatable;
  }

  getMonthlyPayment() {
    let totalAmount: number;

    if (!this.isScaleWithFee) {
      totalAmount = parseFloat(this.amount_credit());
    } else {
      totalAmount = parseFloat(this.amount_credit()) + parseFloat(this.amount_credit()) * (this.simulation.APR / 100);
    }

    let duration = parseInt(this.simulation.echeance);
    return APRCalculator.monthlyPayment(this.simulation.tauxAnnuelDebiteurFixe, duration, totalAmount + this.simulation.fees)
  }

    slotsManager = new SlotsManagerImpl();

    openInformationAdvices = false;

    openClientWarrantyOffer = false;

    totalAmount = this.simulation.purchaseAmount;
    echeanceCost = (parseFloat(this.totalAmount) / parseInt(this.simulation.echeance)).toFixed(2).replace('.',',');

    get isNotVAC() {
        return this.simulation.selectedOffer != "CREDIT";
    }

    get borrowerPrestationChoiceSynthesis() {
        if (this.choixClient.borrowerPrestation) {
            return "J'adhère à l'assurance facultative, et bénéficierai des garanties\n" +
                "                    'DC INVALIDITE ITT EMPRUNTEUR / Assistance vie quotidienne'";
        } else {
            return "Je n'adhère pas à l'assurance facultative, et ne bénéficierai pas des garanties\n" +
                "                    'DC INVALIDITE ITT EMPRUNTEUR / Assistance vie quotidienne'";
        }
    }

    get coBorrowerPrestationChoiceSynthesis() {
        if(this.choixClient.coBorrowerPrestation) {
            return "J'adhère à à l'assurance facultative, et bénéficierai des garanties\n" +
                "                    'Décès/Invalidité/Maladie T2'";
        }
        else {
            return "Je n'adhère pas à l'assurance facultative, et ne bénéficierai pas des garanties\n" +
                "                    'Décès/Invalidité/Maladie T2'";
        }
    }

    get creditAmount() {
        return parseFloat(this.simulation.totalAmount).toFixed(2);
    }

    get monthlyPayment() {
        return parseFloat(this.simulation.monthlyPayment).toFixed(2);
    }

    get lastPayment() {
        let totalAmount = parseFloat(this.amount_credit());
        return (totalAmount - ((parseInt(this.simulation.echeance) - 1) * parseFloat(this.monthlyPayment))).toFixed(2).replace('.',',');
    }


    showClientWarrantyOffers() {
        this.openClientWarrantyOffer = true;
    }

    showInformationAdvices() {
        this.openInformationAdvices = true;
    }

    closeClientWarrantyOffers() {
        this.openClientWarrantyOffer = false;
    }

    closeInformationAdvices() {
        this.openInformationAdvices = false;
    }

    checkedInformationAdvices() {
        this.openInformationAdvices = false;
        this.goToNextDropMenu();
    }

    goToMenu(index: number) {
        this.$emit("getCurrentSlot",this.slotsManager.shownSlot);
        this.slotsManager.showTheSlot(index);
    }

    goToNextDropMenu() {
        this.slotsManager.incrementTheCounter();
        this.$emit("getCurrentSlot",this.slotsManager.shownSlot);
    }

    goToNextPage(etude) {
        this.$emit("nextPage", {
            etude: etude,
        });
    }

    footerRightCallback(){
        if (this.slotsManager.shownSlot == 0 && (!this.choixClient.borrowerPrestation && !this.choixClient.coBorrowerPrestation)) {
            this.showInformationAdvices();
        }
        else {
            this.goToNextDropMenu();
        }
    }

    chooseOneDecision(etude) {
        this.setCreditCard(this.choixClient.creditCard);
        this.goToNextPage(etude);
    }

    showAmount(amount: string) {
        if (amount == null || amount == "")
            return "0,00";

        return amount.replace(".",",");
    }


    amount_credit() {
        if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount != '') {
            let purchaseAmount = parseFloat(this.simulation.purchaseAmount);
            let bringAmount = parseFloat(this.simulation.bringAmount);

            return (purchaseAmount - bringAmount).toFixed(2);
        } else {
            if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount == '')
                return this.simulation.purchaseAmount;
            else
                return ('0.00')
        }
    }
}

