


















import {Vue} from "vue-class";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";


@Component({})
export default class DropMenuWrapper extends Vue {

    @Prop({type: String, default: ''})
    title;

    @Prop({
        type: Boolean,
        default: false
    })
    showModificationLink;

    @Prop({
        type:Boolean,
        default: false
    })
    showSlot;


    showTheSlot()
    {
        this.$emit("backTo", this.title);
    }

}

