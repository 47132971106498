






import {Vue} from "vue-class";
import Component from "vue-class-component";

@Component({})
export default class Outils extends Vue {

}

