







































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {namespace, State, Action} from "vuex-class";
import File from "@/components/inputs/file.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {assetFile} from "@/utils/misc";
import Foot from "@/components/complex-components/footer.vue";
import VueSignaturePad from "vue-signature-pad";
import ESignWarningWrapper from "@/views/modals/ESignWarningWrapper.vue";

let information = namespace('informationState');
@Component({
    components: {
        ESignWarningWrapper, VueSignaturePad, Foot, BasicModal, File, Checkbox
    }
})
export default class SignatureElectronique4Wrapper extends Vue {

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addError') addError;
    @Action('notifications/addSuccess') addSuccess;
    @information.State('coBorrowerSign') coBorrowerSign;

    signaturePad;
    isModalOpened = false;

    mounted() {
        this.signaturePad = this.$refs.signaturePad;
    }

    onErase() {
        this.signaturePad.clearSignature();
    }

    onConfirm() {
        if (this.signaturePad.isEmpty()) {
            this.addError({message: "Vous n'avez pas signé le document."});
        } else {
            if (!this.coBorrowerSign) {
                this.$router.push("/reponse-definitive");
            } else {
                this.isModalOpened = true;
            }
        }
    }

    onNextPage() {
        this.$router.push('/esign/5');
    }

    file(path: string): string {
        return assetFile(path);
    }
}
