import getDefaultClientChoiceState from "@/store/modules/subscription/defaultStates/defaultClientChoiceState";

const state: {
    borrowerPrestation: boolean,
    coBorrowerPrestation: boolean,
    creditCard: boolean,
    financialProposition: string,
    maxFinancialProposition: string,
    TDF: string,
    TAEGF: string
} = getDefaultClientChoiceState();

// getters
const getters = {};

// actions
const actions = {
    setEmptyClientChoiceData({commit}) {
        commit("setBorrowerPrestation",false);
        commit("setCoBorrowerPrestation",false);
        commit("setCreditCard",false);
    }
};

// mutations
const mutations = {
    setBorrowerPrestation(state, borrowerPrestation) {
        state.borrowerPrestation = borrowerPrestation;
    },
    setCoBorrowerPrestation(state, coBorrowerPrestation) {
        state.coBorrowerPrestation = coBorrowerPrestation;
    },
    setCreditCard(state, creditCard) {
        state.creditCard = creditCard;
    },
    resetDefaultState(state) {
        Object.assign(state, getDefaultClientChoiceState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
