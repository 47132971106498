




























































import {Vue} from "vue-class";
import Component from "vue-class-component";
import SectionTitle from "@/components/displayers/section-title.vue";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import Dropdown from "@/components/inputs/dropdown.vue";
import DefaultInput from "@/components/inputs/default-input.vue";
import DateInput from "@/components/inputs/date-input.vue";
import countries from "@/utils/countries";
import Foot from "@/components/complex-components/footer.vue";
import {Mutation, namespace} from "vuex-class";
import situationState from "@/store/modules/subscription/situationState";

let information = namespace('informationState');

@Component({
    components: {DefaultInput, DateInput, Dropdown, SectionTitle, DropMenuWrapper, Foot},
})
export default class Situation extends Vue {
    @information.State('coBorrowerSign') coBorrowerSign: boolean;
    @Mutation('situationState/setCivilState') setCivilState;
    @Mutation('situationState/setBorrowerSituation') setBorrowerSituation;
    @Mutation('situationState/setCoBorrowerSituation') setCoBorrowerSituation;

    situation = situationState.state;

    right = {text: "Confirmer", callback: this.goToNextPage};

    slotsManager = new SlotsManagerImpl();

    countries = countries.countries;

    idHouseTypesList = [
        {display: "Autres", value: 1},
        {display: "Locataire", value: 2},
        {display: "Logé par administration", value: 3},
        {display: "Logé par conjoint propriétaire", value: 4},
        {display: "Logé par employeur", value: 5},
        {display: "Logé par parents ou enfants", value: 6},
        {display: "Logé par un ami ou sous location", value: 7},
        {display: "Logé par un autre membre de la famille", value: 8},
        {display: "Propriétaire avec prêt(s) en cours", value: 9},
        {display: "Propriétaire sans remboursement", value: 10},
    ];

    profession = [
        {display: "Agent/employé (public/armées)", value: 1},
        {display: "Artisan", value: 2},
        {display: "Artiste, journaliste", value: 3},
        {display: "Cadre moyen (médico-social)", value: 4},
        {display: "Cadre moyen (privé)", value: 5},
        {display: "Cadre moyen (public/armées)", value: 6},
        {display: "Cadre supérieur (privé)", value: 7},
        {display: "Cadre supérieur (public/armées)", value: 8},
        {display: "Chauffeur, conducteur", value: 9},
        {display: "Commerçant", value: "valeur9"},
        {display: "Contremaître/agent de maîtrise", value: 10},
        {display: "Employé de bureau (privé)", value: 11},
        {display: "Employé de commerce", value: 12},
        {display: "Employé de service (privé)", value: 13},
        {display: "Etudiant", value: 14},
        {display: "Exploitant agricole/maritime", value: 15},
        {display: "Gérant de société", value: 16},
        {display: "Instituteur, maître auxiliaire", value: 17},
        {display: "Intérimaire", value: 18},
        {display: "Ouvrier", value: 19},
        {display: "Policier, militaire, gendarme", value: 20},
        {display: "Professeur, chercheur, médecin", value: 21},
        {display: "Profession libérale", value: 22},
        {display: "Rentier", value: 23},
        {display: "Retraité", value: 24},
        {display: "Salarié agricole/maritime", value: 25},
        {display: "Sans emploi", value: 26},
        {display: "Sans profession", value: 27},
        {display: "Sans technicien", value: 28},
    ];

    contract = [
        {display: "CDD", value: "CDD"},
        {display: "CDI", value: "CDI"},
        {display: "Contrat d'apprentissage", value: "apprentissage"},
        {display: "Intérim", value: "interim"},
        {display: "Non concerné", value: "NonConcerne"},
        {display: "Stagiaire", value: "stagiaire"},
    ];

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextPage() {
        this.$emit("nextPage");
    }
}

