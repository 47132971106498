import {BaseValidatorRule} from "../BaseValidatorRule";

export class PhoneRule extends BaseValidatorRule{

    constructor(errorMessage="",protected isMobile=false){
        super(errorMessage);
        if(!errorMessage)
            this.errorMessage = "Numéro de téléphone "+(this.isMobile?"mobile":"")+" invalide";
    }

    getDefaultErrorMessage(): string {
        return "Numéro de téléphone invalide";
    }

    validate(value: any):boolean {
        if (value == null || value == "") {
            return true;
        }
        return this.isMobile ?
            value.match(/^0(6|7)[0-9]{8}$/)!=null :
            value.match(/^0[1-9][0-9]{8}$/)!=null;
    }

}