
import {generateUniqueId} from "@/utils/IdGenerator";
import {Popup} from "@/typings/Popup";

const state:{popups:Array<Popup>} = {
    /**
     * the list of popup that are displaying
     */
    popups: Array<Popup>()
};

let lastZIndex = 1;

// actions
const actions = {
    /**
     * add a popup to the popups list
     * @param state
     * @param commit
     * @param payload
     * @returns {Promise<Object>}
     */
    addPopup: ({state,commit},payload:{component:string, props:{[key:string]:any}})=>{
        return new Promise( (resolve,reject) => {
            let popup : Popup = {
                id: generateUniqueId(state.popups),
                popupComponent: payload.component,
                vBindArray: payload.props || {},
                zIndex : lastZIndex
            };
            popup.vBindArray['confirmCallback'] = resultData => {
                commit('removePopupFromState',popup);
                resolve(resultData);
            };
            popup.vBindArray['zIndex'] = lastZIndex;
            popup.vBindArray['abortCallback'] = resultData => {
                commit('removePopupFromState',popup);
                reject(resultData);
            };
            commit('addPopupToState',popup);
            lastZIndex = lastZIndex +3;
        });
    },
    /**
     * Add a prompt popup to the popups list
     * @param dispatch
     * @param props
     * @returns {Object}
     */
    prompt({dispatch},props?:{text?:string,confirmText?:string,abortText?:string}){
            return dispatch('addPopup',{component:'prompt-popup',props: props || {}});
    },

    /**
     * Add a prompt popup to the popups list
     * @param dispatch
     * @param props
     * @returns {Object}
     */
    notify({dispatch},props?:{text?:string,confirmText?:string}){
        return dispatch('addPopup',{component:'notify-popup',props: props || {}});
    },

    /**
     * Clear all the popups in the list
     * @param state
     * @param commit
     * @returns {Promise<Object>}
     */
    clearAll({state,commit}){
        return new Promise( (resolve,reject) => {
            state.popups.forEach(
                (popup) => {
                    popup.vBindArray.abortCallback();
                    commit('removePopupFromState', popup)
                }
            );
            resolve();
        });
    }
};

// mutations
const mutations = {
    /**
     * add a popup to the state
     * @param state
     * @param popup
     */
    addPopupToState (state, popup: Popup){
        state.popups.push(popup);
    },
    /**
     * remove the popup to the state
     * @param state
     * @param popup
     */
    removePopupFromState (state, popup: Popup){
        let popupIndex = state.popups.findIndex(popupItem => popup.id == popupItem.id);
        state.popups.splice(popupIndex,1);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

