










import Component from "vue-class-component";
import Vue from "vue";
import {assetImg} from "@/utils/misc";
import {Prop} from "vue-property-decorator";
import {Getter} from "vuex-class";

@Component({})
export default class MenuButton extends Vue {

    @Getter('canUseGridDisplay') canUseGridDisplay;


    @Prop()
    icon: string;

    @Prop()
    label: string;

    @Prop()
    path: string;

    oldFashion = "wh-newest-fashion";

    created() {
        if(!this.canUseGridDisplay) {
            this.oldFashion = "wh-old-fashion";
        }
    }

    getIcon() {
        return this.image(this.icon);
    }

    image(path: string) {
        return assetImg(path);
    }
}

