






















import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

import JustificatoriesDropdownItem from "@/components/justificatories/justif-dropdown-item.vue";

@Component({
    components: {JustificatoriesDropdownItem},
})
export default class JustificatoriesDropdown extends Vue {

    @Prop({default: ""})
    title: string;

    @Prop()
    items: {title: string, src: string, validated: boolean}[];

    @Prop({default: false})
    isActive: boolean;

    get allValidated() {
        return this.items.every((item) => item.validated);
    }

    validateIfAllCompleted(title: string, photoTaken: boolean) {
        let item = this.items.find(item => item.title == title);
        Vue.set(item, "validated", photoTaken);
    }


}

