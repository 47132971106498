
























import {Vue} from "vue-class";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import BasicModal from "@/components/modals/basic-modal.vue";

@Component({components:{BasicModal}})
export default class CreditOfferChoice extends Vue {

    @Prop({default: false})
    isOpened: boolean;

    @Prop({default: ''})
    componentStyle: string;

    onChoice() {
        this.$emit('chosen')
    }
}

