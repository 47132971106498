/**
 * Interface representing a notification
 */
export interface Notification{
    /**
     * a unique id
     */
    id : number;
    /**
     * The notification status
     */
    status : NotificationStatus,
    /**
     * The message that should be displayed
     */
    message : string,
    /**
     * The notification type
     */
    type : NotificationType,
    /**
     * The method that will be called when the notification is dismissed
     * @param payload
     */
    closedCallback ?: (payload?:any)=>any
}
/**
 * Enum representing the a notification status
 */
export enum NotificationStatus{
    ERROR,
    INFO,
    SUCCESS
}
/**
 * Enum representing the a notification type
 */
export enum NotificationType{
    SNACK,
    TOAST
}