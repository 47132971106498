<template>
  <div class="text-center">
    <v-progress-circular
        indeterminate
        color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "loader"
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
