



























import {Vue} from "vue-class";
import Component from "vue-class-component";
import JustificatoriesDropdown from "@/components/justificatories/justif-dropdown.vue";
import Foot from "@/components/complex-components/footer.vue";
import justificatoryState from "@/store/modules/justificatoryState";

@Component({
  components: {JustificatoriesDropdown, Foot},
})
export default class Justificatories extends Vue {

  justificatory = justificatoryState.state;

  idTitle = "Pièce d'identité de l'emprunteur *";
  coBorrowerdTitle = "Pièce d'identité du co-emprunteur";
  ribTitle = "RIB *";

  right={text:"Continuer", callback: this.goToNextPage} ;
  active = "";

  toggleActivate(title) {
    if (this.active == title) {
      this.active = "";
    } else {
      this.active = title;
    }
  }

  goToNextPage() {
    this.$emit("nextPage");
  }


}

