


























import Vue from 'vue'
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
    components: {},
})
export default class Foot extends Vue {

    @Prop()
    rightButton: { text: string, callback: () => any, inversed?: boolean, clickable?: boolean, show : boolean };

    @Prop()
    leftButton: { text: string, callback: () => any, inversed?: boolean, clickable?: boolean, show : boolean };

    @Prop({default: 'footer'})
    footerClass: string

    mounted() {

    }

}


