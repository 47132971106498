import {ValidatorRule} from "@/typings/Validator";


export abstract class BaseValidatorRule implements ValidatorRule{

    errorMessage: string;
    hasPassed = false;


    constructor(errorMessage=""){
        if(errorMessage!="")
            this.errorMessage = errorMessage;
        else
            this.errorMessage = this.getDefaultErrorMessage()

    }

    public run(value:any):boolean{
        this.hasPassed = this.validate(value);
        return this.hasPassed;
    }

    abstract validate(value:any):boolean

    abstract getDefaultErrorMessage(): string;

}