















import Vue from 'vue'
import Component from "vue-class-component";
import Simulation from "@/views/Simulation.vue";
import ChoixClient from "@/views/souscription/ChoixClient.vue";
import {Prop} from "vue-property-decorator";

@Component({
    components: {ChoixClient, Simulation},
})
export default class Breadcrumb extends Vue {

    @Prop()
    pages: any[];

    @Prop()
    currentPage: number;

    get activePage(){
        return this.uniquePages.findIndex(page => this.pages[this.currentPage].title == page.title);
    }

    get uniquePages(){
        return this.pages.filter((page, i) => {
            return this.pages.findIndex((p) => p.title == page.title) === i
        });
    }

    changePage(index) {
        if (index < this.currentPage){
            let pageToGo = index;
            //Due to justificatories that is also at index one
            if(index>1)
                pageToGo++;
            this.$emit("change-page", pageToGo);
        }
    }


}
