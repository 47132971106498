





















































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {
    ContextAndHeaderManager,
    WalletContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import {Action} from "vuex-class";
import OldInput from "@/components/inputs/old-input.vue";
import EntryDatatable from "@/components/complex-components/entryDatatable.vue";
import RemovePurchaseConfirmWrapper from "@/views/modals/RemovePurchaseConfirmWrapper.vue";
import AppHeader from '@/components/headers/AppHeader.vue';
import PageHeader from '@/components/headers/PageHeader.vue';

@Component({
    components: {AppHeader,PageHeader,RemovePurchaseConfirmWrapper, EntryDatatable, OldInput}
})
export default class PortefeuilleDetails extends Vue implements ContextAndHeaderManager {

    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;

    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

    confirmRemoveEntryIndex = null;
    modalConfirmRemoveEntryDisplayed = false;

    rightPartFontSize = '12px';
    rightPartInputHeight = '18px';
    rightPartInputPadding = '0 2px';

    headers1 = [
        {display: 'N° de carte'},
        {display: 'Titulaire'},
        {display: 'Statut'},
        {display: 'Action SAV'},
    ];

    entries1 = {
        '0': [
            '<i class="fa fa-search"></i> 549538XXXXXX5764',
            'Emprunteur',
            'DDE FAITE AU FAB',
            '',
        ],
        '1': [
            '',
            'Emprunteur',
            '',
            '<button class="c-easy-button-plain">Demande CB</button>',
        ],
        '2': [
            '',
            'Co-emprunteur',
            '',
            '',
        ],
    };

    headers2 = [
        {display: "Date d'achat"},
        {display: 'Magasin'},
        {display: 'Montant achat financé'},
        {display: 'Barème'},
        {display: 'N° mouvement achat financé'},
        {display: 'N° facture'},
        {display: 'N° de plan'},
        {display: "Annulation d'achat"},
    ];

    entries2 = {
        '0': [
            '14/01/2020',
            'IKEA ST LAZARE',
            '852,00€',
            'IKEA 20K PAYANT OFFRE PERMANENTE COUT 10%',
            '4060091',
            '',
            '3',
            '<button class="c-easy-button-plain">Annuler</button>',
        ],
        '1': [
            '15/01/2020',
            'IKEA ST LAZARE',
            '950,00€',
            'IKEA 10X SANS FRAIS RECURRENTE 2 PAR AN',
            '4000413',
            '',
            '2',
            '<button class="c-easy-button-plain">Annuler</button>',
        ],
    };

    // keep them to avoid bugs in typing of values in fields. Vue needs variables to store the values of inputs, or fields would be cleaned.
    generalData = {
        numfid1: '',
        refext1: '',
        numfid2: '',
        refext2: '',
        address: '10 rue des chenes',
        complAddress: 'Appt 690',
        location: '',
        zipCode: '77210',
        city: 'Avon',
        fixedPhone: '',
        mobilePhone: '06 10 72 53 15',
        email: 'jgauthe@ca-cf.fr',
    };


    get totalAmount() {
        let sum = 0;

        Object.keys(this.entries2).forEach(key => {
            let strPrice = this.priceOfLineOfEntry(key);
            let fltPrice = strPrice.split("€")[0].replace(",",".");
            sum += parseFloat(fltPrice);
        });

        return sum.toFixed(2)+" €";
    }

    created() {
        this.updateHeaderOfPageOnChange();
    }

    purchaseEntry() {
        this.$router.push('/portefeuille/' + this.$route.params.foldernumber + '/achat');
    }

    confirmRemoveEntry(index) {
        this.confirmRemoveEntryIndex = index;
        this.modalConfirmRemoveEntryDisplayed = true;
    }

    removeEntry() {
        if (this.confirmRemoveEntryIndex != null) {
            this.modalConfirmRemoveEntryDisplayed = false;
            Vue.delete(this.entries2, this.confirmRemoveEntryIndex);
        }
    }

    priceOfLineOfEntry(lineKey) {
        return this.entries2[lineKey][2];
    }
}

applyMixins(PortefeuilleDetails, [WalletContextAndHeaderManagerImpl]);

