export default () => {
    return {
        borrower: {
            netIncomeExceptedFees: '3000.00',
            familyIncome: '0.00',
            otherIncomes: '0.00',
            rentsOrRealEstateReimbursement: '0.00',
            otherCredits: '0.00',
            otherCharges: '0.00',
        },
        coBorrower: {
            coBorrowerNetIncomeExceptedFees: '1825.00',
            coBorrowerOtherIncomes: '0.00',
        },
        bankInformation: {
            rib: 'borrowerRib',
            bic: 'BOUSFRPPXXX',
            iban: 'FR76 4061 8803 2800 0405 5646 153',
            accountOwner: 'TEST',
            bankName: 'CIC/SNVB',
            seniority: '2001',
        }
    }
}
