






















import {Vue} from "vue-class";
import Component from "vue-class-component";
import JustificatoriesDropdown from "@/components/justificatories/justif-dropdown.vue";
import Foot from "@/components/complex-components/footer.vue";

import {Mutation} from "vuex-class";

@Component({
    components: {JustificatoriesDropdown, Foot},
})
export default class AdditionalJustificatories extends Vue {

    @Mutation('htmlFeatures/betterChangeText') betterChangeText;

    domJustItems = [
        {title: 'Prendre en photo le recto', src: "img/ENGIE.png", validated: false},
    ];

    wageJustItems = [
        {title: 'Prendre en photo le recto', src: "img/bulletin-paie.png", validated: false},
    ];

    domJustTitle = "Justificatif de domicile";
    wageJustTitle = "Justificatif de revenus";

    right={text:"Continuer", callback: this.goToNextPage} ;
    active = "";


    created(){
        this.betterChangeText("PAGE D'ECHANGE CLIENT_VENDEUR : C.Renouv.Std IKEA");
    }

    toggleActivate(title) {
        if (this.active == title) {
            this.active = "";
        } else {
            this.active = title;
        }
    }

    goToNextPage() {
        this.$router.push("/esign/1");
    }



}

