










import Vue from 'vue'
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({})
export default class SectionTitle extends Vue {

    @Prop()
    title: string;

    @Prop({default: '#000'})
    color: string;
}
