










import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import SectionTitle from "@/components/displayers/section-title.vue";
import Radio from "@/components/inputs/radio.vue";
import {Prop} from "vue-property-decorator";
import AvisFavorable from "@/views/souscription/reponse-de-principe/AvisFavorable.vue";
import EtudeComplementaire from "@/views/souscription/reponse-de-principe/EtudeComplementaire.vue";
import EtudeApprofondie from "@/views/souscription/reponse-de-principe/EtudeApprofondie.vue";

@Component({
    components: {EtudeApprofondie, EtudeComplementaire, AvisFavorable, Radio, SectionTitle, DropMenuWrapper}
})
export default class ReponseDePrincipe extends Vue {

    @Prop()
    bundle;

    etude: string = 'favorable';
    ModalValidateIsOpen = false;

  slotsManager = new SlotsManagerImpl();

    created() {
        if (this.bundle.etude !== undefined) {
            this.etude = this.bundle.etude;
        }
    }

  changeModalValidate(){
    this.ModalValidateIsOpen = !this.ModalValidateIsOpen;
    this.$emit('isValidateOpen', this.ModalValidateIsOpen);

  }

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextPage() {
        this.$emit("nextPage");
    }
}

