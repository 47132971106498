import {BaseValidatorRule} from "../BaseValidatorRule";
export class IbanRule extends BaseValidatorRule {

    getDefaultErrorMessage(): string {
        return "L'IBAN est invalide";
    }

    validate(value: any) {
        if (value == null || value == "") {
            return true;
        }
        if (value.length != 27) {
            return false;
        }
        let stringToProcess = value.substring(4, 27) + value.substring(0, 4);
        stringToProcess = stringToProcess.toUpperCase();
        let stringNumber = "";
        for (let i = 0; i < 27; i++) {
            switch (stringToProcess.charAt(i)) {
                case "A":
                    stringNumber += "10";
                    break;
                case "B":
                    stringNumber += "11";
                    break;
                case "C":
                    stringNumber += "12";
                    break;
                case "D":
                    stringNumber += "13";
                    break;
                case "E":
                    stringNumber += "14";
                    break;
                case "F":
                    stringNumber += "15";
                    break;
                case "G":
                    stringNumber += "16";
                    break;
                case "H":
                    stringNumber += "17";
                    break;
                case "I":
                    stringNumber += "18";
                    break;
                case "J":
                    stringNumber += "19";
                    break;
                case "K":
                    stringNumber += "20";
                    break;
                case "L":
                    stringNumber += "21";
                    break;
                case "M":
                    stringNumber += "22";
                    break;
                case "N":
                    stringNumber += "23";
                    break;
                case "O":
                    stringNumber += "24";
                    break;
                case "P":
                    stringNumber += "25";
                    break;
                case "Q":
                    stringNumber += "26";
                    break;
                case "R":
                    stringNumber += "27";
                    break;
                case "S":
                    stringNumber += "28";
                    break;
                case "T":
                    stringNumber += "29";
                    break;
                case "U":
                    stringNumber += "30";
                    break;
                case "V":
                    stringNumber += "31";
                    break;
                case "W":
                    stringNumber += "32";
                    break;
                case "X":
                    stringNumber += "33";
                    break;
                case "Y":
                    stringNumber += "34";
                    break;
                case "Z":
                    stringNumber += "35";
                    break;
                default:
                    stringNumber += stringToProcess.charAt(i);
            }
        }
        let checkSum = parseInt(stringNumber.substring(0, 4)) % 97;
        checkSum = parseInt(checkSum + stringNumber.substring(4, 9)) % 97;

        checkSum = parseInt(checkSum + stringNumber.substring(9, 14)) % 97;

        checkSum = parseInt(checkSum + stringNumber.substring(14, 19)) % 97;

        checkSum = parseInt(checkSum + stringNumber.substring(19, 24)) % 97;

        checkSum = parseInt(checkSum + stringNumber.substring(24)) % 97;

        return checkSum == 1;
    }
}