


































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import SectionTitle from "@/components/displayers/section-title.vue";
import Radio from "@/components/inputs/radio.vue";
import {Prop} from "vue-property-decorator";
import SendContractWrapper from "@/views/modals/SendContractWrapper.vue";
import Foot from "@/components/complex-components/footer.vue";
import {namespace} from "vuex-class";
import DesignTable from "@/components/complex-components/design-table.vue";
import BasicModal from "@/components/modals/basic-modal.vue";


let subscription = namespace('subscriptionState');
let simulation = namespace('simulationState');
@Component({
  components: {DesignTable, Foot, SendContractWrapper, Radio, SectionTitle, DropMenuWrapper, BasicModal}
})
export default class AvisFavorable extends Vue {
  @subscription.State('purchaseAmount') purchaseAmount;
  @simulation.State('selectedOffer') selectedOffer;

  @Prop()
  bundle;

  slotsManager = new SlotsManagerImpl();

  isModalValidateOpen = false;

  signatureElectronic: boolean = null ;
  signatureElectronicChoices = [{label: "Oui", value: true}, {label: "Non", value: false}];

  datatable = [
    ['N° de carte', 'Titulaire'],
    ['504526XXXXXXXXX5104', 'Emprunteur']
  ];

  get isNotVAC() {
    return this.selectedOffer != "CREDIT";
  }

  onContinue() {
    // console.log(this.signatureElectronic);
    //   if(this.signatureElectronic == true)
    //       this.$router.push("additional-justificatories");
    //   else
    //       this.$router.push("/esign/1");

    if (this.signatureElectronic === true){
      this.isModalValidateOpen = !this.isModalValidateOpen;
      this.$emit('isValidateOpen', this.isModalValidateOpen);
    }
  }

  goToMenu(index: number) {
    this.slotsManager.showTheSlot(index);
  }

  goToNextPage() {
    this.$emit("nextPage");
  }
}

