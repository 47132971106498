import {BaseValidatorRule} from "../BaseValidatorRule";
import moment from 'moment';

export class DateRule extends BaseValidatorRule {

    readonly pFormat;

    constructor(errorMessage="", format="YYYY-MM-DD HH:mm:ss"){
        super(errorMessage);
        this.pFormat = format;
    }


    getDefaultErrorMessage(): string {
        return "Date invalide";
    }

    validate(value: any): boolean {
        if (value == null || value == "") {
            return true;
        }
        let date = moment(value,this.pFormat,true);
        return date.isValid() && date.year() > 1900
    }
}