import {reject} from "q";

const actions = {
    downloadExcel({commit}) {
        return new Promise(resolve => {
            new Promise(() => {return true;})
                .then(res => resolve(res))
                .catch(err => reject(err))
        })
    }
};

export default {
    namespaced: true,
    actions
}
