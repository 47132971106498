












































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import SectionTitle from "@/components/displayers/section-title.vue";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import DefaultInput from "@/components/inputs/default-input.vue";
import Dropdown from "@/components/inputs/dropdown.vue";
import Radio from "@/components/inputs/radio.vue";
import IncomesAndChargesWrapper from "@/views/modals/IncomesAndChargesWrapper.vue";
import ClientChoicesSynthesis from "@/views/modals/ClientChoicesSynthesis.vue";
import Foot from "@/components/complex-components/footer.vue";
import {Action, Getter, Mutation, namespace} from "vuex-class";
import incomeAndBankState from "@/store/modules/subscription/incomeAndBankState";
import justificatoryState from "@/store/modules/justificatoryState";
import informationState from "@/store/modules/subscription/informationState";
import situationState from "@/store/modules/subscription/situationState";


let information = namespace('informationState');

let justificatory = namespace('justificatoryState');


@Component({
    components: {
        ClientChoicesSynthesis,
        IncomesAndChargesWrapper, SectionTitle, DropMenuWrapper, DefaultInput, Radio, Dropdown, Foot
    },
})
export default class RevenusEtBanque extends Vue {
    @justificatory.State('ribItems') ribItems: Array<any>;
    @information.State('coBorrowerSign') coBorrowerSign: boolean;

    @Getter('situationState/getProfessionOfBorrower') getProfessionOfBorrower;

    @Mutation('incomeAndBankState/setBorrowerIncome') setBorrowerIncome;
    @Mutation('incomeAndBankState/setCoBorrowerIncome') setCoBorrowerIncome;
    @Mutation('incomeAndBankState/setBankInformation') setBankInformation;

    @Action('incomeAndBankState/setEmptyBankData') setEmptyBankData;

    situation = situationState.state;
    information = informationState.state;
    incomeAndBank = incomeAndBankState.state;

    right={text:"Confirmer", callback: this.footerRightCallback} ;

    ribTypesChoices=[{label:"RIB emprunteur",value:"borrowerRib"},{label:"RIB compte-joint",value:"joinedAccountRib"}];

    bankNamesList=[
        {display:"Americain Express Bank", value:"Americain-Express-Bank"},
        {display: "Autres", value: "other"},
        {display: "Banque AGF", value: "AGF"},
        {display: "Banque Populaire", value: "Populaire"},
        {display: "Banque Postale", value: "Postale"},
        {display: "Banque Sofinco", value: "Sofinco"},
        {display: "BNP", value: "BNP"},
        {display: "BRED", value: "BRED"},
        {display: "C.I.O", value: "C.I.O"},
        {display: "Caisse d'épargne", value: "Caisse-epargne"},
        {display: "CIC/SNVB", value: "CIC/SNVB"},
        {display: "Crédit Agricole", value: "Agricole"},
        {display: "Crédit du Nord", value: "Nord"},
        {display: "Crédit municipal", value: "municipal"},
        {display: "Crédit Mutuel", value: "Mutuel"},
        {display: "FINANC. PAIEMENTS ELECTRONIQUES", value: "FINANC"},
        {display: "HSBC", value: "HSBC"},
        {display: "LCL", value: "LCL"},
        {display: "Pas de banque", value: "nothing"},
        {display: "Pinto Sotomayor", value: "Sotomayor"},
        {display: "Société Générale", value: "Générale"},
        {display: "Société Marseillaise de Crédit", value: "Marseillaise"},
        {display: "SOGENAL", value: "SOGENAL"},
        {display: "Trésor Public", value: "Trésor-Public"},
        {display: "U.P.B", value: "U.P.B"},
    ];

    slotsManager = new SlotsManagerImpl();

    openIncomesAndChargesWrapper = false;

    openClientChoicesSynthesisWrapper = false;


    created() {
      this.$parent.$on('showCCSWrapper', this.showCCSWrapper);
        if (this.coBorrowerSign)
            this.ribTypesChoices.push({label:"RIB co-emprunteur",value:"coBorrowerRib"});
    }


    showIACWrapper() {
        this.openIncomesAndChargesWrapper = true;
        this.$emit('showFicheDialogue', true);
    }

    closeIASWrapper() {
        this.openIncomesAndChargesWrapper = false;
        this.$emit('showFicheDialogue', false);
    }

    showCCSWrapper() {
        this.openIncomesAndChargesWrapper = false;
        this.$emit('showFicheDialogue', false);
        this.openClientChoicesSynthesisWrapper = true;
    }

    closeCCSWrapper() {
        this.openClientChoicesSynthesisWrapper = false;
    }

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextDropMenu() {
        this.slotsManager.incrementTheCounter();
    }

    goToNextPage() {
        this.openClientChoicesSynthesisWrapper = false;
        this.$emit("nextPage");
    }

    footerRightCallback(){
        if(this.slotsManager.shownSlot == 1){
            this.showIACWrapper()
        } else {
            this.goToNextDropMenu()
        }
    }

    // showBankInformationBorrower(){
    //     if (this.ribItems[0].validated === true ){
    //         return true;
    //     }
    //     else {
    //         this.setEmptyBankData();
    //         return true;
    //     }
    // }
}

