import {BaseValidatorRule} from "../BaseValidatorRule";

export class RegexRule extends BaseValidatorRule{

    constructor(errorMessage="",protected regex: RegExp){
        super(errorMessage);
    }

    getDefaultErrorMessage(): string {
        return "Format invalide";
    }

    validate(value: string):boolean {
        if (value == null || value == "") {
            return true;
        }
        return value.match(this.regex)!= null
    }

}