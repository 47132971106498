










import Vue from 'vue'
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({})
export default class InformationDisplay extends Vue {

    @Prop()
    label: string;

    @Prop()
    value: string;
}
