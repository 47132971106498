

















import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";

@Component({})
export default class Radio extends Vue {

    @Prop()
    title: string;

    @Prop()
    radios: Array<{ label: string, value: string }>;

    @Prop()
    name: string;

    @Prop()
    value: any;

    @Prop()
    position: any;
}
