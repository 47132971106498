import {BaseValidatorRule} from "../BaseValidatorRule";

export class AgeRule extends BaseValidatorRule {

    private pFormat;

    constructor(errorMessage="", format="YYYY-MM-DD"){
        super(errorMessage);
        this.pFormat = format;
    }

    getDefaultErrorMessage(): string {
        return "Age invalide (la personne est mineure)";
    }

    validate(value: any): boolean {
        return true
    }
}