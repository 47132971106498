import {BaseValidatorRule} from "../BaseValidatorRule";

export class EmailRule extends BaseValidatorRule{

    getDefaultErrorMessage(): string {
        return "L'email est invalide";
    }

    validate(value: any):boolean {
        if(value==null || value==""){
            return true;
        }
        return value.match(/^.{2,}@.{2,}\.[a-z]{2,}$/)!=null;
    }
}