






































































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import SectionTitle from "@/components/displayers/section-title.vue";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import DefaultInput from "@/components/inputs/default-input.vue";
import DateInput from "@/components/inputs/date-input.vue";
import Dropdown from "@/components/inputs/dropdown.vue";
import Radio from "@/components/inputs/radio.vue";
import InternationalPhoneNumber from "@/components/inputs/international-phone-number.vue";
import countries from "@/utils/countries";
import Foot from "@/components/complex-components/footer.vue";
import {Action, Mutation, namespace} from "vuex-class";
import informationState from "@/store/modules/subscription/informationState";
import justificatoryState from "@/store/modules/justificatoryState";
import BasicModal from "@/components/modals/basic-modal.vue";

let justificatory = namespace('justificatoryState');

@Component({
    components: {InternationalPhoneNumber, Radio, Dropdown, DefaultInput, SectionTitle, DropMenuWrapper, Foot, DateInput, BasicModal},
})


export default class Informations extends Vue {
    @justificatory.State('coBorrowerIdItems') coBorrowerIdItems: Array<any>;
    @justificatory.State('borrowerIdItems') borrowerIdItems: Array<any>;
    @Mutation('informationState/setCoBorrowerSign') setCoBorrowerSign;
    @Mutation('informationState/setBorrower') setBorrower;
    @Mutation('informationState/setCoBorrower') setCoBorrower;
    @Mutation('informationState/setContactInformation') setContactInformation;

    @Action('informationState/setEmptyCoBorrowerInformationData') setEmptyCoBorrowerInformationData;
    @Action('informationState/setEmptyBorrowerInformationData') setEmptyBorrowerInformationData;

    information = informationState.state;

    right = {text: "Confirmer", callback: this.footerRightCallback};

    slotsManager = new SlotsManagerImpl();


    isOpen: boolean = true;

    idPieceTypesList = [
          {display: "Carte nationale d'identité", value: "CNI"},
          {display: "Passeport", value: "passeport"},
          {display: "Permis de conduire", value: "permis"},
          {display: "Autres", value: "other"},
          {display: "Ue + assimiles validité -5 ans", value: "ue-5"},
          {display: "Piece non présente", value: "nothing"},
          {display: "Ue + assimiles validité 5 ans & +", value: "ue-5+"},
          {display: "Carte sejour - 10 ans (hors ue)", value: "not-eu-10"},
          {display: "Carte sejour 10 ans &+ (hors ue)", value: "not-eu+10"}
      ];

    civicStatesChoice = [{label: "Mr", value: "MR"}, {label: "Mme", value: "MME"}, {label: "Mle", value: "MLE"}];

    countriesList = countries.countries;


    familySituationsList = [
        {display: "Célibataire", value: "single"},
        {display: "Divorcé(e)", value: "divorced"},
        {display: "Marié(e)", value: "married"},
        {display: "Pacsé(e)", value: "pacs"},
        {display: "Séparé(e)", value: "separate"},
        {display: "En couple", value: "relationship"},
        {display: "Veuf(veuve)", value: "widow"}
    ];

    nbDependentChildrenList = [{display: "0", value: "0"}, {display: "1", value: "1"}, {display: "2", value: "2"}, {display: "3", value: "3"}, {display: "4", value: "4"}, {display: "5", value: "5"}, {display: "6+", value: "6"}];

    CoBorrowerChoice = [{label: "Oui", value: true}, {label: "Non", value: false}];



    titleOfSectionAboutOtherBorrower() {
        if (this.information.coBorrowerSign) return "Informations co-emprunteur";
        return "Informations conjoint";
    }

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
        this.$emit("getCurrentSlot",this.slotsManager.shownSlot);
    }

    goToNextDropMenu() {
        this.slotsManager.incrementTheCounter();
        this.$emit("getCurrentSlot",this.slotsManager.shownSlot);
    }

    goToNextMenu(){
      this.slotsManager.incrementTheCounter();
      this.$emit("getCurrentSlot",this.slotsManager.shownSlot);


    }
    goToNextPage() {
        this.$emit("nextPage");
    }

    footerRightCallback() {
        if (this.slotsManager.shownSlot == 2) {
            this.goToNextPage()
        } else {
            this.goToNextDropMenu()
        }
    }

    created() {
      if (!this.information.mobileVersion){
        this.slotsManager.incrementTheCounter();
        this.$emit("getCurrentSlot",this.slotsManager.shownSlot);
      }
      this.$parent.$on('incrementSlot', this.goToNextDropMenu);
    }
    // mounted() {
    //     if (!(this.borrowerIdItems[0].validated === true || this.borrowerIdItems[1].validated === true)) {
    //         this.setEmptyBorrowerInformationData();
    //     }
    //     if (!(this.coBorrowerIdItems[0].validated === true || this.coBorrowerIdItems[1].validated === true)) {
    //         this.setEmptyCoBorrowerInformationData();
    //     }
    // }
  onClose() {
    this.isOpen = false;
  }


}

