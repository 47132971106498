

























import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
    components: {},
})
export default class JustificatoriesDropdownItem extends Vue {

    @Prop({default: ""})
    title: string;

    @Prop({default: ""})
    photoSrc: string;

    showPhoto = false;
    photoTaken = false;

    hidePhoto(){
        this.showPhoto=false;
        this.photoTaken = true;
        this.$emit("photoTaken", true)
    }

    takePhoto(){
        if(!this.photoTaken)
            this.showPhoto = true;
    }

    removePhoto(){
        this.photoTaken = false;
        this.$emit("photoTaken", false)
    }
}

