











































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Action} from "vuex-class";
import File from "@/components/inputs/file.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {assetFile} from "@/utils/misc";
import Foot from "@/components/complex-components/footer.vue";

@Component({
    components: {
        Foot, BasicModal, File, Checkbox
    }
})
export default class SignatureElectronique2Wrapper extends Vue {

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addSuccess') addSuccess;

    checkboxes = [
        {
            title: "Je reconnais avoir pris connaissance des offres de financement alternatives amortissables.",
            value: false
        },
        {
            title: "Je reconnais avoir pris connaissance de la Fiche d'informations Européennes Normalisées et de son annexe, avoir pris connaissance de la Fiche d'informations et de Conseils de l'Assurance Des Emprunteurs et avoir renseigné et validé la fiche de dialogue.",
            value: false
        },
        {
            title: "Je reconnais être en possession, en avoir pris connaissance et avoir accepté les termes de l'offre de contrat de crédit et, le cas échéant, de la notice d'information des contrats collectifs d'assurance facultative.",
            value: false
        },
        {
            title: "Je reconnais avoir pris connaissance des conditions cartes ainsi que ses conditions particulières de fonctionnement notamment tarifaires, et les accepter.",
            value: false
        },
        {
            title: "J'autorise le créancier dont le nom figure dans le mandat SEPA à envoyer des instructions à ma banque pour débiter mon compte, et ma banque à débiter mon compte conformément aux instructions du créancier.",
            value: false
        },
    ];

    get isClickable() {
        return this.checkboxes.every(value => value.value === true);
    }

    onConfirm() {
        if (this.isClickable) {
            this.$router.push('/esign/3');
        }
    }

    file(path: string): string {
        return assetFile(path);
    }
}
