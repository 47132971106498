export var CLIENT_SELLER_TEXT = "PAGE D'ÉCHANGE CLIENT-VENDEUR : ";
export var CLIENT_SUMMARY_TEXT = "CR Agile EM";
export var SIMULATION = "CR Agile EM";
export var SUBSCRIPTION = "CR Agile EM";
export var SHOP = "CR Agile EM";


export class PageHeaderTextAbs {
    header: string;

    protected constructor(text: string = "") {
        this.header = text;
    }

    getPageHeaderText()
    {
        return this.header;
    }

    hasNotEmptyText()
    {
        return this.header != null && this.header !== "";
    }
}

export class BasicPageHeaderText extends PageHeaderTextAbs
{
    header: string;

    constructor(text: string = "")
    {
        super("");
    }
}

export class SimulationPageHeaderText extends PageHeaderTextAbs
{
    header: string;

    constructor(text: string = "")
    {
        super(SIMULATION);
    }
}

export class SubscriptionPageHeaderText extends PageHeaderTextAbs
{
    header: string;

    constructor(text: string = "")
    {
        super(CLIENT_SELLER_TEXT+text);
    }
}

export class WalletPageHeaderText extends PageHeaderTextAbs
{
    header: string;

    constructor(text: string = "")
    {
        super(CLIENT_SUMMARY_TEXT);
    }
}
