




import {Vue} from "vue-class";
import Component from 'vue-class-component'

@Component({})
export default class NotFound extends Vue {

}
