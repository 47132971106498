

















































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {namespace} from "vuex-class";

let simulation = namespace('simulationState');
@Component({
    components: {}
})
export default class ReponseDefinitive extends Vue {

    @simulation.State('purchaseAmount') purchaseAmount;
    @simulation.State('bringAmount') bringAmount;

    onTerminate() {
        this.$router.push('/home');
    }

    totalPurchase() {
        let result = parseFloat(this.purchaseAmount) - parseFloat(this.bringAmount);

        if (isNaN(result))
            result = 0;

        return result.toFixed(2).replace('.',',');
    }

}

