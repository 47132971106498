import Contexts from "@/utils/context";

const changeContextAndHeaderOfPage = "changeContextAndHeaderOfPage";

const SUBSCRIPTION_TEXT = "SOUSCRIPTION";
const WALLET_TEXT = "PORTEFEUILLE";
const SIMULATION_TEXT = "SIMULATION";


export interface ContextAndHeaderManager {
    updateHeaderOfPageOnChange(textForHeader: string),
}


export class BasicContextAndHeaderManagerImpl implements ContextAndHeaderManager {
    updateHeaderOfPageOnChange(textForHeader: string = "")
    {
        if(this[changeContextAndHeaderOfPage] != null)
            this[changeContextAndHeaderOfPage]({newContext: Contexts.noContext});
    }
}


export class SimulationContextAndHeaderManagerImpl implements ContextAndHeaderManager {
    updateHeaderOfPageOnChange(textForHeader: string = SIMULATION_TEXT)
    {
        if(this[changeContextAndHeaderOfPage] != null)
            this[changeContextAndHeaderOfPage]({newContext: Contexts.simulationContext, suppTextForHeader: textForHeader});
    }
}


export class SubscriptionContextAndHeaderManagerImpl implements ContextAndHeaderManager {
    updateHeaderOfPageOnChange(textForHeader: string = SUBSCRIPTION_TEXT)
    {
        if(this[changeContextAndHeaderOfPage] != null)
            this[changeContextAndHeaderOfPage]({newContext: Contexts.subscriptionContext, suppTextForHeader: textForHeader});
    }
}


export class WalletContextAndHeaderManagerImpl implements ContextAndHeaderManager {
    updateHeaderOfPageOnChange(textForHeader: string = WALLET_TEXT)
    {
        if(this[changeContextAndHeaderOfPage] != null)
            this[changeContextAndHeaderOfPage]({newContext: Contexts.walletContext, suppTextForHeader: textForHeader});
    }
}