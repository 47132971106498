import getDefaultSituationState from "@/store/modules/subscription/defaultStates/defaultSituationState";

const state: { civilState: any, borrower: any, coBorrower: any, profession: Array<object> } = getDefaultSituationState();

// getters
const getters = {
    getProfessionOfBorrower(state) {
        let found =  state.profession.find(element => element.value == state.borrower.profession);

        if (found == null)
            return "";
        return found.display;
    }
};

// actions
const actions = {
    setEmptySituationData(state) {
        let civilStateValue = ['housing', 'since'];
        let borrowerAndCoBorrowerValues = ['profession', 'contractType', 'since', 'employer', 'employerPhoneNumber',
            'city', 'department', 'country'];

        civilStateValue.forEach((key) => {
            state.commit('setCivilState', {key: key, value: ''});
        });
        borrowerAndCoBorrowerValues.forEach((key) => {
            state.commit('setBorrowerSituation', {key: key, value: ''});
            state.commit('setCoBorrowerSituation' , {key: key, value: ''});
        });
    },
};

// mutations
const mutations = {
    setCivilState(state, value: { key: string, value: string }) {
        state.civilState[value.key] = value.value;
    },
    setBorrowerSituation(state, value: { key: string, value: string }) {
        state.borrower[value.key] = value.value;
    },
    setCoBorrowerSituation(state, value: { key: string, value: string }) {
        state.coBorrower[value.key] = value.value;
    },
    resetDefaultState(state) {
        Object.assign(state, getDefaultSituationState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
