






































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Action, Getter} from "vuex-class";
import File from "@/components/inputs/file.vue";
import ModalSendMail from "@/views/modals/SendMailWrapper.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {assetFile} from "@/utils/misc";
import informationState from "@/store/modules/subscription/informationState";
import situationState from "@/store/modules/subscription/situationState";

@Component({
    components: {
        ModalSendMail, BasicModal, File, Checkbox
    }
})
export default class IncomesAndChargesWrapper extends Vue {

    @Getter('situationState/getProfessionOfBorrower') getProfessionOfBorrower;
    @Getter('informationState/getFamilySituationOfBorrower') getFamilySituationOfBorrower;

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addSuccess') addSuccess;

    information = informationState.state;
    situation = situationState.state;

    @Prop({default: false})
    isOpened: boolean;

    acceptedConditions = false;

    get isClickable() {
        return this.acceptedConditions;
    }

    get address() {
        return (this.information.contactInformation.address!="" ? this.information.contactInformation.address+" " : "")
            + this.information.contactInformation.complementAddress;
    }

    toggleAcceptation() {
        this.acceptedConditions = !this.acceptedConditions;
    }

    onConfirm() {
        this.$emit('confirm')
    }

    onClose() {
        this.$emit('close');
    }

    file(path: string): string {
        return assetFile(path);
    }
}
