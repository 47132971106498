









































import {Vue} from "vue-class";
import Component from "vue-class-component";
import BasicModal from "@/components/modals/basic-modal.vue";
import File from "@/components/inputs/file.vue";
import {Prop} from "vue-property-decorator";
import EntryDatatable from "@/components/complex-components/entryDatatable.vue";


@Component({
    components: {EntryDatatable, BasicModal, File}
})
export default class ClientWarrantyOffers extends Vue {

    @Prop({default: false})
    isOpened: boolean;
    
    checkmark = "img/checkmark.png";
    crossedOutCircle = "img/crossed_out_circle.png";

    warrantiesHeaders = [
        'IKEA',
        'Actifs moins de 67 ans',
        'Inactifs moins de 67 ans',
        'Entre 67 et 84 ans inclus',
    ];

    warrantiesEntriesHeaders = {
        death: "Décès",
        PTIA:  "Perte totale et irréversible d'autonomie (PTIA)\n<=> Invalidité assimilée au décès",
        IPT:   "Incapacité permanente et totale (IPT)\n<=> Invalidité",
        ITTT:  "Incapacité temporaire totale de travail (ITTT)\n<=> Arrêt de travail de plus de 3 mois",
    };

    warrantiesEntriesData = {
        death: [{src:this.checkmark, alt:"Oui"}, {src:this.checkmark, alt:"Oui"}, {src:this.checkmark, alt:"Oui"}],
        PTIA:  [{src:this.checkmark, alt:"Oui"}, {src:this.checkmark, alt:"Oui"}, {src:this.checkmark, alt:"Oui"}],
        IPT:   [{src:this.checkmark, alt:"Oui"}, {src:this.crossedOutCircle, alt:"Non"}, {src:this.crossedOutCircle, alt:"Non"}],
        ITTT:  [{src:this.checkmark, alt:"Oui"}, {src:this.crossedOutCircle, alt:"Non"}, {src:this.crossedOutCircle, alt:"Non"}],
    };


    onClose() {
        this.$emit('close');
    }
}
