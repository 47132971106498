export default () => {
    return {
        borrowerIdItems: [
            {title: 'Prendre en photo le recto', src: "img/CNI-emprunteur-recto.png", validated: false},
            {title: 'Prendre en photo le verso', src: "img/CNI-emprunteur-verso.png", validated: false}
        ],

        coBorrowerIdItems: [
            {title: 'Prendre en photo le recto', src: "img/CNI-co-emprunteur-recto.png", validated: false},
            {title: 'Prendre en photo le verso', src: "img/CNI-co-emprunteur-verso.png", validated: false}
        ],

        ribItems: [
            {title: 'Prendre en photo le recto', src: "img/RIB.png", validated: false},
        ],

        domJustItems: [
            {title: 'Prendre en photo le recto', src: "img/ENGIE.png", validated: false},
        ],
    }
}
