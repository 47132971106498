










import Vue from 'vue'
import {assetImg} from "@/utils/misc";
import Component from "vue-class-component";
import {Getter} from "vuex-class";
import HeaderText from "@/components/headers/header-text.vue";


@Component({
    components: {HeaderText}
})
export default class PageHeader extends Vue {
    @Getter('htmlFeatures/getTextOfPageHeader') getTextOfPageHeader;
    @Getter('htmlFeatures/isTextOfPageHeaderNotEmpty') isTextOfPageHeaderNotEmpty;

    get text() {
        return this.getTextOfPageHeader;
    }

    get isNotBasicContext(): boolean {
        return this.isTextOfPageHeaderNotEmpty;
    }

    image(path: string): string {
        return assetImg(path);
    }

}
