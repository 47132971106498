

















import {Vue} from "vue-class";
import {assetImg} from "@/utils/misc";
import Component from "vue-class-component";

@Component({})
export default class HeaderVerticalMenu extends Vue {

    showChoices = false;

    options = [];

    hideChoices() {
        this.showChoices = false;
    }

    showOrHideChoices() {
        if (this.options.length > 0) {
            this.showChoices = !this.showChoices;
        } else {
            this.$router.push('/home');
        }
    }

    executeTheActionOfChoice(action = null) {
        if (action != null) {
            action();
        }
    }

    goToHome() {
        this.$router.push("/").catch(() => {
        });
        this.showChoices = false;
    }

    image(path: string) {
        return assetImg(path);
    }
}

