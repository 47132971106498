export default {
    countries: [
        {value: 'AF', display: "Afghanistan"},
        {value: 'ZA', display: "Afrique du Sud"},
        {value: 'AL', display: "Albanie"},
        {value: 'DZ', display: "Algérie"},
        {value: 'DE', display: "Allemagne"},
        {value: 'AD', display: "Andorre"},
        {value: 'AO', display: "Angola"},
        {value: 'AI', display: "Anguilla"},
        {value: 'AQ', display: "Antarctique"},
        {value: 'AG', display: "Antigua-et-Barbuda"},
        {value: 'AN', display: "Antilles néerlandaises"},
        {value: 'SA', display: "Arabie saoudite"},
        {value: 'AR', display: "Argentine"},
        {value: 'AM', display: "Arménie"},
        {value: 'AW', display: "Aruba"},
        {value: 'AU', display: "Australie"},
        {value: 'AT', display: "Autriche"},
        {value: 'AZ', display: "Azerbaïdjan"},
        {value: 'BS', display: "Bahamas"},
        {value: 'BH', display: "Bahreïn"},
        {value: 'BD', display: "Bangladesh"},
        {value: 'BB', display: "Barbade"},
        {value: 'BY', display: "Bélarus"},
        {value: 'BE', display: "Belgique"},
        {value: 'BZ', display: "Belize"},
        {value: 'BJ', display: "Bénin"},
        {value: 'BM', display: "Bermudes"},
        {value: 'BT', display: "Bhoutan"},
        {value: 'BO', display: "Bolivie"},
        {value: 'BA', display: "Bosnie-Herzégovine"},
        {value: 'BW', display: "Botswana"},
        {value: 'BR', display: "Brésil"},
        {value: 'BN', display: "Brunéi Darussalam"},
        {value: 'BG', display: "Bulgarie"},
        {value: 'BF', display: "Burkina Faso"},
        {value: 'BI', display: "Burundi"},
        {value: 'KH', display: "Cambodge"},
        {value: 'CM', display: "Cameroun"},
        {value: 'CA', display: "Canada"},
        {value: 'CV', display: "Cap-Vert"},
        {value: 'EA', display: "Ceuta et Melilla"},
        {value: 'CL', display: "Chili"},
        {value: 'CN', display: "Chine"},
        {value: 'CY', display: "Chypre"},
        {value: 'CO', display: "Colombie"},
        {value: 'KM', display: "Comores"},
        {value: 'CG', display: "Congo-Brazzaville"},
        {value: 'KP', display: "Corée du Nord"},
        {value: 'KR', display: "Corée du Sud"},
        {value: 'CR', display: "Costa Rica"},
        {value: 'CI', display: "Côte d’Ivoire"},
        {value: 'HR', display: "Croatie"},
        {value: 'CU', display: "Cuba"},
        {value: 'DK', display: "Danemark"},
        {value: 'DG', display: "Diego Garcia"},
        {value: 'DJ', display: "Djibouti"},
        {value: 'DM', display: "Dominique"},
        {value: 'EG', display: "Égypte"},
        {value: 'SV', display: "El Salvador"},
        {value: 'AE', display: "Émirats arabes unis"},
        {value: 'EC', display: "Équateur"},
        {value: 'ER', display: "Érythrée"},
        {value: 'ES', display: "Espagne"},
        {value: 'EE', display: "Estonie"},
        {value: 'VA', display: "État de la Cité du Vatican"},
        {value: 'FM', display: "États fédérés de Micronésie"},
        {value: 'US', display: "États-Unis"},
        {value: 'ET', display: "Éthiopie"},
        {value: 'FJ', display: "Fidji"},
        {value: 'FI', display: "Finlande"},
        {value: 'FR', display: "France"},
        {value: 'GA', display: "Gabon"},
        {value: 'GM', display: "Gambie"},
        {value: 'GE', display: "Géorgie"},
        {value: 'GS', display: "Géorgie du Sud et les îles Sandwich du Sud"},
        {value: 'GH', display: "Ghana"},
        {value: 'GI', display: "Gibraltar"},
        {value: 'GR', display: "Grèce"},
        {value: 'GD', display: "Grenade"},
        {value: 'GL', display: "Groenland"},
        {value: 'GP', display: "Guadeloupe"},
        {value: 'GU', display: "Guam"},
        {value: 'GT', display: "Guatemala"},
        {value: 'GG', display: "Guernesey"},
        {value: 'GN', display: "Guinée"},
        {value: 'GQ', display: "Guinée équatoriale"},
        {value: 'GW', display: "Guinée-Bissau"},
        {value: 'GY', display: "Guyana"},
        {value: 'GF', display: "Guyane française"},
        {value: 'HT', display: "Haïti"},
        {value: 'HN', display: "Honduras"},
        {value: 'HU', display: "Hongrie"},
        {value: 'BV', display: "Île Bouvet"},
        {value: 'CX', display: "Île Christmas"},
        {value: 'CP', display: "Île Clipperton"},
        {value: 'AC', display: "Île de l'Ascension"},
        {value: 'IM', display: "Île de Man"},
        {value: 'NF', display: "Île Norfolk"},
        {value: 'AX', display: "Îles Åland"},
        {value: 'KY', display: "Îles Caïmans"},
        {value: 'IC', display: "Îles Canaries"},
        {value: 'CC', display: "Îles Cocos - Keeling"},
        {value: 'CK', display: "Îles Cook"},
        {value: 'FO', display: "Îles Féroé"},
        {value: 'HM', display: "Îles Heard et MacDonald"},
        {value: 'FK', display: "Îles Malouines"},
        {value: 'MP', display: "Îles Mariannes du Nord"},
        {value: 'MH', display: "Îles Marshall"},
        {value: 'UM', display: "Îles Mineures Éloignées des États-Unis"},
        {value: 'SB', display: "Îles Salomon"},
        {value: 'TC', display: "Îles Turks et Caïques"},
        {value: 'VG', display: "Îles Vierges britanniques"},
        {value: 'VI', display: "Îles Vierges des États-Unis"},
        {value: 'IN', display: "Inde"},
        {value: 'ID', display: "Indonésie"},
        {value: 'IQ', display: "Irak"},
        {value: 'IR', display: "Iran"},
        {value: 'IE', display: "Irlande"},
        {value: 'IS', display: "Islande"},
        {value: 'IL', display: "Israël"},
        {value: 'IT', display: "Italie"},
        {value: 'JM', display: "Jamaïque"},
        {value: 'JP', display: "Japon"},
        {value: 'JE', display: "Jersey"},
        {value: 'JO', display: "Jordanie"},
        {value: 'KZ', display: "Kazakhstan"},
        {value: 'KE', display: "Kenya"},
        {value: 'KG', display: "Kirghizistan"},
        {value: 'KI', display: "Kiribati"},
        {value: 'KW', display: "Koweït"},
        {value: 'LA', display: "Laos"},
        {value: 'LS', display: "Lesotho"},
        {value: 'LV', display: "Lettonie"},
        {value: 'LB', display: "Liban"},
        {value: 'LR', display: "Libéria"},
        {value: 'LY', display: "Libye"},
        {value: 'LI', display: "Liechtenstein"},
        {value: 'LT', display: "Lituanie"},
        {value: 'LU', display: "Luxembourg"},
        {value: 'MK', display: "Macédoine"},
        {value: 'MG', display: "Madagascar"},
        {value: 'MY', display: "Malaisie"},
        {value: 'MW', display: "Malawi"},
        {value: 'MV', display: "Maldives"},
        {value: 'ML', display: "Mali"},
        {value: 'MT', display: "Malte"},
        {value: 'MA', display: "Maroc"},
        {value: 'MQ', display: "Martinique"},
        {value: 'MU', display: "Maurice"},
        {value: 'MR', display: "Mauritanie"},
        {value: 'YT', display: "Mayotte"},
        {value: 'MX', display: "Mexique"},
        {value: 'MD', display: "Moldavie"},
        {value: 'MC', display: "Monaco"},
        {value: 'MN', display: "Mongolie"},
        {value: 'ME', display: "Monténégro"},
        {value: 'MS', display: "Montserrat"},
        {value: 'MZ', display: "Mozambique"},
        {value: 'MM', display: "Myanmar"},
        {value: 'NA', display: "Namibie"},
        {value: 'NR', display: "Nauru"},
        {value: 'NP', display: "Népal"},
        {value: 'NI', display: "Nicaragua"},
        {value: 'NE', display: "Niger"},
        {value: 'NG', display: "Nigéria"},
        {value: 'NU', display: "Niue"},
        {value: 'NO', display: "Norvège"},
        {value: 'NC', display: "Nouvelle-Calédonie"},
        {value: 'NZ', display: "Nouvelle-Zélande"},
        {value: 'OM', display: "Oman"},
        {value: 'UG', display: "Ouganda"},
        {value: 'UZ', display: "Ouzbékistan"},
        {value: 'PK', display: "Pakistan"},
        {value: 'PW', display: "Palaos"},
        {value: 'PA', display: "Panama"},
        {value: 'PG', display: "Papouasie-Nouvelle-Guinée"},
        {value: 'PY', display: "Paraguay"},
        {value: 'NL', display: "Pays-Bas"},
        {value: 'PE', display: "Pérou"},
        {value: 'PH', display: "Philippines"},
        {value: 'PN', display: "Pitcairn"},
        {value: 'PL', display: "Pologne"},
        {value: 'PF', display: "Polynésie française"},
        {value: 'PR', display: "Porto Rico"},
        {value: 'PT', display: "Portugal"},
        {value: 'QA', display: "Qatar"},
        {value: 'HK', display: "R.A.S. chinoise de Hong Kong"},
        {value: 'MO', display: "R.A.S. chinoise de Macao"},
        {value: 'QO', display: "régions éloignées de l’Océanie"},
        {value: 'CF', display: "République centrafricaine"},
        {value: 'CD', display: "République démocratique du Congo"},
        {value: 'DO', display: "République dominicaine"},
        {value: 'CZ', display: "République tchèque"},
        {value: 'RE', display: "Réunion"},
        {value: 'RO', display: "Roumanie"},
        {value: 'GB', display: "Royaume-Uni"},
        {value: 'RU', display: "Russie"},
        {value: 'RW', display: "Rwanda"},
        {value: 'EH', display: "Sahara occidental"},
        {value: 'BL', display: "Saint-Barthélémy"},
        {value: 'KN', display: "Saint-Kitts-et-Nevis"},
        {value: 'SM', display: "Saint-Marin"},
        {value: 'MF', display: "Saint-Martin"},
        {value: 'PM', display: "Saint-Pierre-et-Miquelon"},
        {value: 'VC', display: "Saint-Vincent-et-les Grenadines"},
        {value: 'SH', display: "Sainte-Hélène"},
        {value: 'LC', display: "Sainte-Lucie"},
        {value: 'WS', display: "Samoa"},
        {value: 'AS', display: "Samoa américaines"},
        {value: 'ST', display: "Sao Tomé-et-Principe"},
        {value: 'SN', display: "Sénégal"},
        {value: 'RS', display: "Serbie"},
        {value: 'CS', display: "Serbie-et-Monténégro"},
        {value: 'SC', display: "Seychelles"},
        {value: 'SL', display: "Sierra Leone"},
        {value: 'SG', display: "Singapour"},
        {value: 'SK', display: "Slovaquie"},
        {value: 'SI', display: "Slovénie"},
        {value: 'SO', display: "Somalie"},
        {value: 'SD', display: "Soudan"},
        {value: 'LK', display: "Sri Lanka"},
        {value: 'SE', display: "Suède"},
        {value: 'CH', display: "Suisse"},
        {value: 'SR', display: "Suriname"},
        {value: 'SJ', display: "Svalbard et Île Jan Mayen"},
        {value: 'SZ', display: "Swaziland"},
        {value: 'SY', display: "Syrie"},
        {value: 'TJ', display: "Tadjikistan"},
        {value: 'TW', display: "Taïwan"},
        {value: 'TZ', display: "Tanzanie"},
        {value: 'TD', display: "Tchad"},
        {value: 'TF', display: "Terres australes françaises"},
        {value: 'IO', display: "Territoire britannique de l'océan Indien"},
        {value: 'PS', display: "Territoire palestinien"},
        {value: 'TH', display: "Thaïlande"},
        {value: 'TL', display: "Timor oriental"},
        {value: 'TG', display: "Togo"},
        {value: 'TK', display: "Tokelau"},
        {value: 'TO', display: "Tonga"},
        {value: 'TT', display: "Trinité-et-Tobago"},
        {value: 'TA', display: "Tristan da Cunha"},
        {value: 'TN', display: "Tunisie"},
        {value: 'TM', display: "Turkménistan"},
        {value: 'TR', display: "Turquie"},
        {value: 'TV', display: "Tuvalu"},
        {value: 'UA', display: "Ukraine"},
        {value: 'EU', display: "Union européenne"},
        {value: 'UY', display: "Uruguay"},
        {value: 'VU', display: "Vanuatu"},
        {value: 'VE', display: "Venezuela"},
        {value: 'VN', display: "Viêt Nam"},
        {value: 'WF', display: "Wallis-et-Futuna"},
        {value: 'YE', display: "Yémen"},
        {value: 'ZM', display: "Zambie"},
        {value: 'ZW', display: "Zimbabwe"}
    ]
}
