export default () => {
    return {
        borrowerPrestation: null,
        coBorrowerPrestation: false,
        creditCard: true,
        financialProposition: "3000,00",
        maxFinancialProposition: "3000,00",
        TDF: "0,000",
        TAEGF: "0,000"
    }
}
