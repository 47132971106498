














































import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import CustomInput from "./custom-input"

@Component({})
export default class OldInput extends CustomInput {

    @Prop({default: 'text'})
    type: string;

    @Prop({default: 'false'})
    required: string;

    @Prop({default: false})
    disabled: boolean;

    @Prop({default: 'left'})
    align: string;

    @Prop()
    suffix: string;

    @Prop({default: '16px'})
    size: number;

    @Prop({default: '40px'})
    height: number;

    @Prop({default: '0 19px'})
    paddingInput: string;

    inputType = "text";
    inputmode = "";
    textAlignment = this.align;

    mounted() {
        if (this.required == 'true' && this.label !== '') {
            this.asterisk = ' *';
        }
        if (this.type == "number")
            this.inputmode = "numeric";

        this.inputType = this.type;
    }

    emitValue(value: string) {
        if (this.type == "number") {
            value = value.replace(',', '.');
        }
        this.$emit('input', value)
    }

}

