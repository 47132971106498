

























import Vue from 'vue';
import Component from "vue-class-component";
import HeaderDisconnection from "@/components/headers/header-disconnection.vue";
import HeaderVerticalMenu from "@/components/headers/header-vertical-menu.vue";
import HeaderImage from "@/components/headers/header-image.vue";
import HeaderText from "@/components/headers/header-text.vue";
import {Getter} from "vuex-class";

@Component({
    components: {HeaderText, HeaderDisconnection, HeaderVerticalMenu, HeaderImage}
})
export default class AppHeader extends Vue {

    @Getter('shopData/getShopName') getShopName;

    canGoBack() {
        if (!(RegExp(/^\/$/).test(this.$router.currentRoute.path)
            || RegExp(/\/history/).test(this.$router.currentRoute.path)))
            this.$router.back()
    }
}

