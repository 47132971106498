export default () => {
    return {
        civilState: {
            housing: 10,
            since: '1995-06'
        },
        borrower: {
            profession: 7,
            contractType: 'CDI',
            since: '1990-05',
            employer: 'CACF',
            employerPhoneNumber: '',
            city: 'MASSY',
            department: '91300',
            country: 'FR'
        },
        coBorrower: {
            profession: 12,
            contractType: 'CDI',
            since: '2007-01',
            employer: 'SNCF',
            employerPhoneNumber: '',
            city: 'MASSY',
            department: '91300',
            country: 'FR'
        },
        profession: [
            {display: "Agent/employé (public/armées)", value: 1},
            {display: "Artisan", value: 2},
            {display: "Artiste, journaliste", value: 3},
            {display: "Cadre moyen (médico-social)", value: 4},
            {display: "Cadre moyen (privé)", value: 5},
            {display: "Cadre moyen (public/armées)", value: 6},
            {display: "Cadre supérieur (privé)", value: 7},
            {display: "Cadre supérieur (public/armées)", value: 8},
            {display: "Chauffeur, conducteur", value: 9},
            {display: "Commerçant", value: "valeur9"},
            {display: "Contremaître/agent de maîtrise", value: 10},
            {display: "Employé de bureau (privé)", value: 11},
            {display: "Employé de commerce", value: 12},
            {display: "Employé de service (privé)", value: 13},
            {display: "Etudiant", value: 14},
            {display: "Exploitant agricole/maritime", value: 15},
            {display: "Gérant de société", value: 16},
            {display: "Instituteur, maître auxiliaire", value: 17},
            {display: "Intérimaire", value: 18},
            {display: "Ouvrier", value: 19},
            {display: "Policier, militaire, gendarme", value: 20},
            {display: "Professeur, chercheur, médecin", value: 21},
            {display: "Profession libérale", value: 22},
            {display: "Rentier", value: 23},
            {display: "Retraité", value: 24},
            {display: "Salarié agricole/maritime", value: 25},
            {display: "Sans emploi", value: 26},
            {display: "Sans profession", value: 27},
            {display: "Sans technicien", value: 28},
        ]
    }
}
