






import {assetImg} from "@/utils/misc";
import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component
export default class HeaderImage extends Vue {
    @Prop({
        type: String
    })
    source;

    image(path: string) {
        return assetImg(path);
    }
}
