import {BaseValidatorRule} from "../BaseValidatorRule";

export class ZipCodeRule extends BaseValidatorRule {

    constructor(errorMessage = "") {
        super(errorMessage);
    }

    getDefaultErrorMessage(): string {
        return "Code postal invalide";
    }

    validate(value: any): boolean {
        if (value == null || value == "") {
            return true;
        }
        return value.length == 5;
    }
}