import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {RequiredRule} from "@/validation/rules";
import {SometimesRequiredRule} from "@/validation/rules";

@Component({})
export default class CustomInput extends Vue {

    @Prop()
    label: string;

    @Prop({default: ''})
    placeholder: string;

    @Prop()
    value: string;

    @Prop({default: ''})
    validatorName: string;

    @Prop({default: ''})
    validatorGroup: string;

    @Prop()
    forceShowError: boolean;

    @Prop({default:true})
    editable:boolean;

    @Getter('validation/isValid') isValid;
    @Getter('validation/getValidationMessages') getValidationMessages;
    @Getter('validation/validatorHasRule') validatorHasRule;
    @Getter('validation/shouldDisplayErrorMessage') shouldDisplayErrorMessage;

    dirty = false;

    asterisk="";

     get shouldShowErrorMessage(){

         return (this.value!=null || this.value != "") // seems useless but actually it s used to trigger vue's watch mecanism
             && this.validatorName
             && (this.dirty || this.shouldDisplayErrorMessage(this.validatorGroup))
             && !this.isValid(this.validatorGroup,this.validatorName);
     }

    mounted() {
        this.showAsterisk();
        //noinspection TypeScriptUnresolvedFunction
        this.$watch("forceShowError",(newValue)=>{
            this.dirty = newValue;
        })
    }

    emitValue(value) {
        this.$emit('input', value);
    }

    focusInput(inputRef){
        let toFocus: any;
        toFocus = this.$refs[inputRef];
        toFocus.focus();
    }

    resetDirty(){
        this.dirty = false;
    }

    showAsterisk(){
        if(this.validatorName!=''){
            if(this.validatorHasRule(this.validatorGroup,this.validatorName,RequiredRule) || this.validatorHasRule(this.validatorGroup,this.validatorName,SometimesRequiredRule))
                this.asterisk = "*";
        }
    }
}