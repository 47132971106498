import { render, staticRenderFns } from "./Souscription.vue?vue&type=template&id=fa955604&scoped=true&"
import script from "./Souscription.vue?vue&type=script&lang=ts&"
export * from "./Souscription.vue?vue&type=script&lang=ts&"
import style0 from "./Souscription.vue?vue&type=style&index=0&id=fa955604&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa955604",
  null
  
)

export default component.exports