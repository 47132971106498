import {Validator} from "@/typings/Validator";
import {BaseValidatorRule} from '@/validation/BaseValidatorRule'


export default class BaseValidator implements Validator{

    valid = false;
    touched = false;


    constructor(public rules: Array<BaseValidatorRule>, public value: any){

    }

    setValue(value:any){
        this.value = value;
        this.touched = true;
    }

    run() {
        this.valid = true;
        for(let rule of this.rules){
            this.valid = this.valid && rule.run(this.value)
        }
    }

}