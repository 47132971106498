
























import {Vue} from "vue-class";
import Component from "vue-class-component";
import Foot from "@/components/complex-components/footer.vue";
import {Prop} from "vue-property-decorator";



@Component({
  components: {Foot},
})
export default class ReponseDePrincipeEnd extends Vue {

  @Prop()
  bundle;


  right={text:"Terminer", callback: this.goToNextPage,show:true};
  left={show:false};

  goToNextPage() {
    this.$emit("onTerminer");
  }
}

