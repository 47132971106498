



















































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Action, namespace} from "vuex-class";
import File from "@/components/inputs/file.vue";
import ModalSendMail from "@/views/modals/SendMailWrapper.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {assetFile} from "@/utils/misc";
let simulation = namespace('simulationState');

@Component({
    components: {
        ModalSendMail, BasicModal, File, Checkbox
    }
})
export default class InformationsOAAWrapper extends Vue {

    @simulation.State('purchaseAmount') purchaseAmount: string;
    @simulation.State('bringAmount') bringAmount: string;

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addSuccess') addSuccess;

    @Prop({default: false})
    isOpened: boolean;

    @Prop({default: "PRODUIT"})
    selectedProduct: string;

    acceptedConditions = false;

    openMailWrapper = false;

    get isClickable() {
        return this.acceptedConditions;
    }

    get creditAmount() {
        return (parseFloat(this.purchaseAmount) - parseFloat(this.bringAmount)).toFixed(2).replace('.',',');
    }

    toggleAcceptation() {
        this.acceptedConditions = !this.acceptedConditions;
    }

    onRequestMail() {
        this.openMailWrapper = true;
    }

    onConfirm() {
        this.$emit('confirm');
    }

    onClose() {
        this.$emit('close');
    }

    onSuccess() {
        this.openMailWrapper = false;
        this.addInfo({message: "Vous serez informé une fois l'email envoyé."}).then();
        setTimeout(() => {
            this.addSuccess({message:"Email envoyé avec succès"}).then();
        }, 2000);
    }

    onClosedMail() {
        this.openMailWrapper = false;
    }

    file(path: string): string {
        return assetFile(path);
    }
}
