












import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import SectionTitle from "@/components/displayers/section-title.vue";
import Radio from "@/components/inputs/radio.vue";
import {Prop} from "vue-property-decorator";
import AvisFavorable from "@/views/souscription/reponse-de-principe/AvisFavorable.vue";
import EtudeComplementaire from "@/views/souscription/reponse-de-principe/EtudeComplementaire.vue";
import EtudeApprofondie from "@/views/souscription/reponse-de-principe/EtudeApprofondie.vue";

@Component({})
export default class ReponseDePrincipeClose extends Vue {

  @Prop()
  bundle;


  goToNextPage() {
    this.$emit("nextPage");
  }
}

