import {
    BasicPageHeaderText,
    PageHeaderTextAbs,
    WalletPageHeaderText,
    SubscriptionPageHeaderText, SimulationPageHeaderText
} from "@/store/classes/PageHeaderText";
import Contexts from "@/utils/context";
import {SimulationContextAndHeaderManagerImpl} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import {initialShopData, ShopData} from "@/store/classes/ShopData";

const state:ShopData = initialShopData;

// getters
const getters = {
    getShopName: state => () => {
        return state.name;
    }
};

// actions
const actions = {
    setShopDataFromLogin({commit}, shopData: any) {
        commit('setShopName', shopData.name);
    }
};

// mutations
const mutations = {
    setShopName(state, name: string) {
        state.name = name;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}