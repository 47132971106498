import {
    BasicPageHeaderText,
    PageHeaderTextAbs,
    WalletPageHeaderText,
    SubscriptionPageHeaderText, SimulationPageHeaderText
} from "@/store/classes/PageHeaderText";
import Contexts from "@/utils/context";
import {SimulationContextAndHeaderManagerImpl} from "@/typings/componentsExtensions/ContextAndHeaderManager";

const state: {
    pageHeaderText:any,
    contextOfPage: string
} = {
    pageHeaderText: new BasicPageHeaderText(),
    contextOfPage: Contexts.noContext
};

// getters
const getters = {
    getTextOfPageHeader(state)
    {
        return typeof state.pageHeaderText == "string" ?
            state.pageHeaderText :
            state.pageHeaderText.getPageHeaderText();
    },
    isTextOfPageHeaderNotEmpty(state)
    {
        return typeof state.pageHeaderText == "string" ?
                state.pageHeaderText!= "" :
                state.pageHeaderText.hasNotEmptyText();
    }
};

// actions
const actions = {
    changeContextAndHeaderOfPage({commit}, parameters: {newContext: string, suppTextForHeader?: string})
    {
        commit('changeContextOfPageHeader', parameters.newContext);
        if (parameters.suppTextForHeader) {
            commit('changeTextOfPageHeader', parameters.suppTextForHeader);
        }
        else {
            commit('changeTextOfPageHeader');
        }
    }
};

// mutations
const mutations = {
    changeContextOfPageHeader(state, newContext: string)
    {
        state.contextOfPage = newContext;
    },
    changeTextOfPageHeader(state, suppText: string = "")
    {
        switch (state.contextOfPage) {
            case Contexts.simulationContext:
                state.pageHeaderText = new SimulationPageHeaderText(suppText);
                break;
            case Contexts.subscriptionContext:
                state.pageHeaderText = new SubscriptionPageHeaderText(suppText);
                break;
            case Contexts.walletContext:
                state.pageHeaderText = new WalletPageHeaderText(suppText);
                break;
            default:
                state.pageHeaderText = new BasicPageHeaderText();
                break;
        }
    },

    betterChangeText(state, text){
        state.pageHeaderText = text;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}