

























































import {Vue} from "vue-class";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {namespace} from "vuex-class";
import informationState from "@/store/modules/subscription/informationState";

let subscription = namespace('subscriptionState');

@Component({components:{Checkbox, BasicModal}})
export default class ClientChoicesSynthesis extends Vue {
    @subscription.State('coBorrower') coBorrower: boolean;

    information = informationState.state;

    @Prop({default: false})
    isOpened: boolean;

    @Prop({default: ''})
    componentStyle: string;

    borrowerNoProspection = false;

    borrowerSellOfferByMail = false;

    coBorrowerNoProspection = false;

    coBorrowerSellOfferByMail = false;


    toggleValue(attribute) {
        this[attribute] = !this[attribute];
    }

    onConfirm() {
        this.$emit('confirm');
    }

    onClose() {
        this.$emit('close');
    }
}

